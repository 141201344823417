import { useEffect, useState } from 'react';
import { apiInstance } from 'services/instance';
import { useParams } from 'react-router-dom';
// Other
import { getLogo, toDate } from 'utils';
import ReactPlayer from 'react-player';
import { Paginator, Carousel } from 'components/shared';

import ReactGA from "react-ga"
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

ReactGA.initialize("UA-221463012-1")

const Progress = () => {
    const { name, department } = useParams();
    const [selection, setSelection] = useState('3');
    const [progress, setProgress] = useState([]);
    const [pages, setPages] = useState({ total: 0, from: 0, to: 0, current: 0, last: 0 });
    const [infographic, setInfographic] = useState('');
    const { t } = useTranslation("lang");

    const trad = useSelector((state)=> state.langInfo);

    useEffect(() => {
        fetchProgress({ development: name.toUpperCase(), construction_type: '4', department: '', tower: '' }, 0, true);
    }, []);

    useEffect(() => {
        fetchProgress({
            development: name.toUpperCase(),
            construction_type: selection,
            department: selection === '2' ? department : '',
            tower: selection === '3' ? department.split('-')[1] : '',
        });
    }, [department, name, selection]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const fetchProgress = async (data, page, f) => {
        await apiInstance.post('/work-progress/customer', data, { params: { page } })
            .then(({ data: { items } }) => {
                console.log(items.data);
                f ? getInfographic(items[0]) :
                    setPages({
                        current: items.current_page,
                        from: items.from,
                        last: items.last_page,
                        to: items.to,
                        total: items.total,
                    });
                    console.log(pages);
                if(localStorage.getItem('lang') === 'es'){
                    setProgress(items.data ?? []);
                }
                else{
                    for( let k in items.data){
                        if( trad.data.hasOwnProperty(items.data[k].description)){
                            console.log(items.data[k].description);
                            console.log(trad.data[items.data[k].description]);
                            items.data[k].description = trad.data[items.data[k].description];
                        }
                    }
                    setProgress(items.data ?? []);
                }
                
                console.log(progress);
            }).catch(console.log);
    };

    const getInfographic = ({ id, real_state_development: dev, ...other }) => {
        const imageUrl = `https://api-portalclientes.idex.cc/storage/infographic/${id}-${dev}/${other.images[0].image}`;
        const videoUrl = `https://youtu.be/${other.video_url}`;
        setInfographic({ imageUrl, videoUrl, description: other.description });
    };

    const handlePagination = (page) => {
        const data = {
            development: name.toUpperCase(),
            construction_type: selection,
            department: selection === '2' ? department : '',
            tower: selection === '3' ? department.split('-')[1] : '',
        };
        fetchProgress(data, page);
    };

    return (
        <>
            <div className="h-auto bg-white rounded-4xl sm:rounded-5xl shadow-lg p-4 sm:p-10">
                <section className="flex flex-col sm:flex-row justify-around items-center sm:mb-10 p-6">
                    <div className="hidden sm:flex items-center justify-center rounded-full h-24 w-24 sm:h-44 sm:w-44 bg-gray-200">
                        <div className="w-4/5">
                            <img src={getLogo(name.toLowerCase())} className="w-full" alt="Logo" />
                        </div>
                    </div>
                    <h4 className="text-2xl sm:text-3xl font-bold text-center mb-2">{t("buildingProgressTitleLabel")}</h4>
                    <select id="type" value={selection} onChange={(e) => setSelection(e.target.value)}
                        className="w-auto sm:w-1/5 input px-2 input-primary rounded-2xl p-2">
                        <option value='1'>{t("amenitySelectLabel")}</option>
                        <option value='2'>{t("apartmentSelectLabel")}</option>
                        <option value='3'>{t("towerSelectLabel")}</option>
                    </select>
                </section>
                {infographic && <div className="h-auto bg-white rounded-4xl sm:rounded-5xl shadow-lg p-4 mb-8">
                    <div className="flex w-full rounded-3xl sm:rounded-4xl overflow-hidden">
                        <img src={infographic.imageUrl} className="w-full object-contain" alt="infography" />
                    </div>
                    <div className="w-full px-4 py-3 bg-brand-100 rounded-b-3xl sm:rounded-b-5xl mt-3">
                        <p className="text-center text-xl">{infographic.description}</p>
                    </div>
                    <div className="flex mt-2 w-full sm:h-96 rounded-3xl sm:rounded-4xl overflow-hidden">
                        <ReactPlayer controls height="100%" width="100%" url={infographic.videoUrl} />
                    </div>
                </div>}

                {!progress.length && <h1 className="text-xl sm:text-3xl font-bold text-red-200 text-center">{t("thereIsNoProgressLabel")}</h1>}

                {progress.map((e, index) =>
                    <section key={index} className="align-center shadow-lg rounded-2xl p-6 space-y-4">
                        <div className="title mb-2">
                            <div className="flex items-center gap-4">
                                <h3 className="text-xl font-bold text-brand-600">{e.real_state_development}</h3>
                                <span className="bg-brand-200 text-xs text-white p-1 rounded-lg">{t("createdOnLabel")}: {toDate(e.created_at)}</span>
                            </div>
                            {e.department && <span className="text-sm text-brand-700">{t("apartmentSelectLabel")}: {e.department}</span>}
                        </div>
                        <Carousel images={e.images} id={e.id} development={e.real_state_development}
                            className="flex h-full bg-gray-200 w-full" />
                        <p>{e.description}</p>

                        {e.video_url && <div className="flex h-96">
                            <ReactPlayer controls height="100%" width="100%" url={e.video_url} />
                        </div>}
                    </section>
                )}
                {progress.length > 0 && <Paginator total={pages.total} pages={{ ...pages }}
                    onChange={(e) => e !== pages.current ? handlePagination(e) : null} />}
            </div>
        </>
    );
};

export default Progress;