// Common
import { useState, createRef, useEffect } from 'react';
// Components
import Modal from 'components/shared/Modal';
import RolesDetails from 'components/RolesDetails';
// Others
import { BiFileFind, BiPencil, BiUserPlus } from 'react-icons/bi';
import { apiInstance } from 'services/instance';
import { swal } from 'utils';
import DataTable from 'components/shared/DataTable';
import { useTranslation } from 'react-i18next';

const Users = () => {
    const [showModal, setShowModal] = useState(false);
    const [showCreate, setshowCreate] = useState(false);
    const [showModalUser, setShowModalUser] = useState(false);
    const [user, setUser] = useState({});
    const [roles, setRoles] = useState([]);
    const [roleState, setRoleState] = useState({});
    const [data, setData] = useState({});
    const { t } = useTranslation("lang");

    let submitBtn = createRef();

    useEffect(() => {
        fetchUsers();
        fetchRoles();
    }, [showCreate]);

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const handleModalUser = (user) => {
        setUser(user);
        setShowModalUser(!showModalUser);
    };

    const handleCreate = () => {
        setshowCreate(!showCreate);
    };

    const handleRolesUpdate = () => {
        const { updated, id, form } = roleState;
        if (updated) {
            updateRole(id, form);
            return
        }
    };

    const handleAction = ({ action, item, page }) => {
        action === 'details' ? handleModalUser(item) : handleDeleteUser(item.id, page);
    };

    const handleDeleteUser = async (id, page) => {
        await apiInstance.delete(`/users/${id}`)
            .then(({ data: res }) => {
                fetchUsers(page);
            }).catch(console.log);
    };

    const updateRole = async (id, form) => {
        await apiInstance.put(`/roles/${id}`, form)
            .then(({ data: res }) => {
                handleModal();
                swal(res.status, res.message);
            }).catch((error) => {
                console.log(error);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!e.target.email) {
            updateUser(user.id, e.target.role.value)
            return;
        }

        createUser({ query: e.target.email.value, role: e.target.role.value });
    };

    const createUser = async (params) => {
        await apiInstance.post('/create-admin-user', params)
            .then(({ data: res }) => {
                swal('success', res.message);
                handleCreate();
            }).catch(({ response: error }) => {
                swal('error', error.data.message);
            });
    };

    const fetchRoles = async () => {
        await apiInstance.get('/roles')
            .then(({ data: res }) => {
                setRoles(res.items);
            }).catch(console.log);
    };

    const fetchUsers = async (page) => {
        await apiInstance.get('/users', { params: { user: 'employee', page } })
            .then(({ data: res }) => {
                setData(parseData(res.items));
            }).catch(console.log)
    }

    const updateUser = async (id, role) => {
        await apiInstance.put(`/users/${id}`, { role })
            .then(({ data: res }) => {
                swal('success', 'Se ha modificado el rol satisfactoriamente');
                fetchUsers();
                handleModalUser();
            }).catch((error) => {
                swal('error', error.response.data.message);
            })
    }

    const handleSearch = async (search) => {
        await apiInstance.post('/search-user', { query: search }, { params: { user: 'employee' } })
            .then(({ data: res }) => {
                setData(parseData(res.items));
                // setData({...res.items, rows: parseData(res.items.data) });
            }).catch(console.log)
    };

    function parseData(items) {
        const rows = items.data.map((e) => {
            return {
                id: e.id,
                name: e.name + ' ' + (e.profile?.last_name.split(' ')[0] ?? ''),
                email: e.email,
                rol: e.roles[0].name,
                active: e.deleted_at ? false : true,
                details: {
                    icon: <BiFileFind />,
                    action: 'details'
                },
                delete: {
                    action: 'delete'
                }
            };
        });

        return ({ ...items, rows });
    }

    return (
        <>
            <div className="h-auto bg-white rounded-4xl sm:rounded-5xl mx-8 shadow-lg p-2 sm:p-10">
                <section className="relative p-2 sm:px-10 sm:mb-10">
                    <h4 className="text-3xl font-bold text-center p-2 sm:p-0">{t("idexUsersLabel")}</h4>
                    <div className="flex justify-center align-center">
                        <button type="button" onClick={() => handleModal()}
                            className="flex items-center gap-2 sm:absolute right-10 btn btn-primary px-3 py-2">
                            <p>{t("rolesUsersLabel")}</p>
                            <BiPencil />
                        </button>
                        <button onClick={handleCreate} className="absolute left-10 p-2 rounded-2xl bg-brand-50 hover:bg-brand-100 focus:outline-none transition">
                            <BiUserPlus className="text-brand" size={30} />
                        </button>
                    </div>
                </section>
                <DataTable onEvent={handleAction} onSearch={handleSearch}
                    columns={['Nombre', 'Correo', 'Rol', '', 'Status']} data={data} onUpdate={(page) => fetchUsers(page)} />
            </div >
            <Modal shown={showModal} toggleModal={handleModal} title="Editar Rol" saved={handleRolesUpdate}>
                <RolesDetails update={setRoleState} />
            </Modal>

            <Modal shown={showCreate} toggleModal={handleCreate} title="Alta usuario" saved={() => submitBtn.click()}>
                <form onSubmit={handleSubmit} id="form" className="w-full space-y-4">
                    <div className="group">
                        <label htmlFor="email" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("emailUsersLabel")}</label>
                        <input id="email" type="email" required className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                        <input type="submit" ref={input => submitBtn = input} hidden />
                    </div>
                    <div className="group">
                        <label htmlFor="role" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("rolUsersLabel")}</label>
                        <select id="role" className="input text-lg px-3 input-primary h-14 rounded-2xl">
                            <option hidden value=''>{t("selectUserLabel")}</option>
                            {roles.map((e, index) => {
                                return (<option key={index} value={e.name} className="capitalize">{e.name}</option>);
                            })}
                        </select>
                    </div>
                </form>
            </Modal>

            <Modal shown={showModalUser} toggleModal={handleModalUser} title="Editar Usuario" saved={() => submitBtn.click()}>
                <form onSubmit={handleSubmit} id="form" className="space-y-4 w-full">
                    <div className="group">
                        <label htmlFor="role" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("rolUsersLabel")}</label>
                        <select id="role" required className="input text-lg px-3 input-primary h-14 rounded-2xl">
                            <option hidden value=''>{t("selectUserLabel")}</option>
                            {roles.map((e, index) => {
                                return (<option key={index} value={e.name} >{e.name}</option>);
                            })}
                        </select>
                        <input type="submit" ref={input => submitBtn = input} hidden />
                    </div>
                </form>
            </Modal>
        </ >
    );
};

export default Users;
