// Common
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Components
import Loading from "components/shared/Loading";
// Others
import { useCreditInfo } from "hooks/useCreditInfo";
import { getBanner, getLogo, toCurrency } from "utils";

import ReactGA from "react-ga";
import { CarouselEscrituration } from "components/shared";
import { useTranslation } from "react-i18next";
ReactGA.initialize("UA-221463012-1");

const Escrituration = () => {
  const { name, index, payment_method, deal_id } = useParams();
  const [type, setType] = useState('contados')
  const {
    items: { card_info: cardInfo },
    areLoading,
  } = useCreditInfo(name, index);
  const [number, setNumber] = useState(1);
  const [info, setInfo] = useState([]);
  const [creditChart, setCreditChart] = useState({});
  const { t } = useTranslation("lang");

  useEffect(() => {
    const infoHandler = () => {
      let dev, credit, paid, toPay, late, date;

      dev = cardInfo.department_development;
      credit = toCurrency(parseFloat(cardInfo.deposit));
      paid = toCurrency(cardInfo.total_customer_paid);
      toPay = toCurrency(cardInfo.deposit - cardInfo.total_customer_paid);
      late = cardInfo.total_late_payments;
      date = new Date(cardInfo.next_payment_date.replace(/-/g, "/"));
      date = date.toLocaleDateString("es-MX", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      setInfo([dev, credit, paid, toPay, late, date]);
    };

    if (!areLoading) {
      infoHandler(cardInfo);
      cChart(
        cardInfo.total_late_payments,
        cardInfo.total_customer_paid,
        cardInfo.deposit - cardInfo.total_customer_paid
      );
    }
  }, [areLoading, cardInfo]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const cChart = (late, paid, toPay) => {
    setCreditChart({
      labels: ["Atrasado", "Adeudo", "Pagado"],
      datasets: [
        {
          data: [late, toPay, paid],
          backgroundColor: ["#F4857B", "#FFD791", "#4CB5AE"],
          borderWidth: 4,
        },
      ],
    });
  };

  return (
    <>
      {areLoading && (
        <div className="flex justify-center items-center h-96">
          <Loading color="#F4857B" type="Oval" width={150} height={150} />
        </div>
      )}
      {!areLoading && (
        <div className="bg-white rounded-5xl shadow-lg z-0">
          <div className="flex sm:h-96 relative w-full">
            <img
              src={cardInfo && getBanner("")}
              className="object-cover object-bottom w-full rounded-4xl"
              alt="Logo"
            />
            <div className="absolute w-full top-24 sm:top-52 items-center justify-center">
              <div className="mx-auto items-center justify-center flex rounded-full h-36 w-36 sm:h-56 sm:w-56 bg-white">
                <div className="items-center justify-center flex rounded-full h-32 w-32 sm:h-52 sm:w-52 mx-auto bg-gray-200">
                  <div className="w-4/5">
                    <img
                      src={cardInfo && getLogo(cardInfo.department_development)}
                      className="w-full"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="data justify-center align-center w-full p-6 mt-10 sm:m-0 sm:p-10">
            <h4 className="text-3xl font-bold text-center my-2">
              {t("deedPhasesLabel")}
            </h4>
            <h4 className="text-3xl font-regular text-center my-5 mb-20">
              {payment_method==='HIPOTECARIO'?'Hipotecario':'Contado'} 
            </h4>
            <div className="sm:flex shadow-lg w-full rounded-4xl px-6 sm:px-16 py-2 sm:py-4 justify-between text-center sm:text-justify"></div>
            <h4 className="text-3xl font-bold text-center my-2">
              {t("phaseLabel")}{number}
            </h4>

            <div className="w-full card shadow-xl  rounded-3xl p-1 sm:p-8  space-x-1/2 ">
              <CarouselEscrituration setNumber={setNumber} name={name} index={index} type={payment_method} deal_id={deal_id} stage={cardInfo.stage} numberPhase={cardInfo.stage_number}/>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Escrituration;
