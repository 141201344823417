// Common
import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
// Components
import Loading from 'components/shared/Loading';
import Dropzone from 'components/shared/Dropzone';
// Others
import { apiInstance } from 'services/instance';
import AsyncSelect from 'react-select/async';
import { classNames, swal } from 'utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { editFaqAsync } from 'redux/redux';

const WorkProgress = ({ onEvent }) => {
    const { register, handleSubmit, control, watch, formState: { errors }, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState([]);
    const [towers, setTowers] = useState([]);
    const [files, setFiles] = useState([]);
    const [selection, setSelection] = useState(0);
    const { t } = useTranslation("lang");
    const dispatch = useDispatch();
    const formFields = watch();

    useEffect(() => {
        fetchConstructionTypes();
        fetchTowers(formFields.development?.label);
    }, [formFields.development]);

    const onSubmit = (data) => {
        setLoading(true);
        formFormat(data);
    };

    const formFormat = (data) => {
        const { development: { label: dev }, advantage, type, url } = data;
        let formData = new FormData();

        formData.append('state_development_bitrix_id', 0);
        formData.append('real_state_development', dev);
        formData.append('description', advantage);
        formData.append('construction_type_id', type);
        formData.append('video_url', url);
        data.department && formData.append('department', data.department.value);
        data.tower && formData.append('tower', data.tower.split(' ')[1]);
        if (files.length > 0) {
            files.map((file) => formData.append('images[]', file, file.name));
        }
        console.log(formData)
        sendProgress(formData);
    }

    const sendProgress = async (data) => {
        if ( localStorage.getItem("lang") === "es"){
            await apiInstance.post('/work-progress', data)
            .then(({ data: response }) => {
                swal('success', response.message);
                onEvent(true);
            }).catch(({ message: error }) => {
                console.log(error);
            });
            setLoading(false);
        }
        else {
            alert("The creation of progresses are only available in spanish");
            setLoading(false);
        }
    };

    const fetchConstructionTypes = async () => {
        await apiInstance.get('/construction-types')
            .then(({ data: response }) => {
                setTypes(response.items.map((e) => { return { value: e.id, label: e.type } }));
                console.log(types);
            }).catch(({ message: error }) => {
                console.log(error);
            });
    };

    const fetchTowers = async (dev) => {
        await apiInstance.post('/tower', { development: dev })
            .then(({ data: response }) => {
                setTowers(response.items.map((e) => { return { value: e.Desarrollo, label: e.Desarrollo } }));
            }).catch(({ message: error }) => {
                console.log(error);
            });
    };

    const loadDevOptions = (input, cb) => {
        apiInstance.post('/development', { query: input })
            .then(({ data: response }) => {
                cb([{ value: response.items.id, label: response.items.name }]);
            }).catch((error) => {
                cb([{ value: '', label: 'No se ha encontrado' }]);
            });
    };

    const loadDptOptions = (input, cb) => {
        apiInstance.post('/department', { id: formFields.development.value, query: input })
            .then(({ data: response }) => {
                cb([{ value: response.items.name, label: response.items.name }]);
            }).catch((error) => {
                cb([{ value: '', label: 'No se ha encontrado' }]);
            });
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'black',
            backgroundColor: state.isSelected ? '#FACBC6' : 'white',
            cursor: 'pointer'
        }),
        control: () => ({
            display: 'flex',
            justifyContent: 'space-between',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    };

    const handleSelection = (value) => {
        setSelection(value);
        reset({ development: formFields.development, department: null, tower: null, advantage: null });
        // setFiles([]);
    };

    const filesHandler = (files) => {
        setFiles(files);
    };

    return (
        <>
            <div>
                <form id="form" onSubmit={handleSubmit(onSubmit)} className="form sm:grid lg:grid-cols-2 gap-5 space-y-4 sm:space-y-0">
                    <div className="group text-left">
                        <label htmlFor="development" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("developmentWorkLabel")}</label>
                        <Controller control={control} defaultValue="" name="development" rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <AsyncSelect onChange={onChange} onBlur={onBlur} value={value} loadOptions={loadDevOptions} styles={customStyles}
                                    className="input text-lg p-0 py-1 input-primary h-14 rounded-2xl" />
                            } />
                        {errors.development && <span className="ml-2 text-brand-400 text-sm">{t("typeDevelopmentNameWorkLabel")}</span>}
                    </div>
                    <div className="group text-left">
                        <label htmlFor="type" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("typeWorkLabel")}</label>
                        <select id="type" {...register("type", { required: true })} onChange={(e) => handleSelection(e.target.value)}
                            className="input text-lg px-3 input-primary h-14 rounded-2xl" value={selection}>
                            <option hidden value=''>{t("selectWorkLabel")}</option>
                            {types.map((e, index) => {
                                return (<option key={index} value={e.value} className="capitalize">{e.label}</option>);
                            })}
                        </select>
                        {errors.type && <span className="ml-2 text-brand-400 text-sm">{t("selectTypeWorkLabel")}</span>}
                    </div>
                    {selection === '2' && <div className="group text-left">
                        <label htmlFor="department" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("apartmentWorkLabel")}</label>
                        <Controller control={control} defaultValue="" name="department" rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <AsyncSelect onChange={onChange} onBlur={onBlur} value={value} loadOptions={loadDptOptions} styles={customStyles}
                                    className="input text-lg p-0 py-1 input-primary h-14 rounded-2xl" />
                            } />
                        {errors.department && <span className="ml-2 text-brand-400 text-sm">{t("typeApartmentWorkLabel")}</span>}
                    </div>}
                    {selection === '3' && <div className="group text-left">
                        <label htmlFor="tower" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("towerWorkLabel")}</label>
                        <select id="tower" {...register("tower", { required: true })} className="input text-lg px-3 input-primary h-14 rounded-2xl">
                            <option hidden value=''>{t("selectWorkLabel")}</option>
                            {towers.map((e, index) => {
                                return (<option key={index} value={e.value} className="capitalize">{e.label}</option>);
                            })}
                        </select>
                        {errors.type && <span className="ml-2 text-brand-400 text-sm">{t("selectTypeDevelopmentWork")}</span>}
                    </div>}
                    <div className={classNames(selection !== '2' && selection !== '3' ? "col-span-2" : "col-span-1", "group text-left")}>
                        <label htmlFor="files" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("imagesWorkLabel")}</label>
                        <Dropzone multiple={selection !== '4'} setFiles={filesHandler} />
                    </div>
                    <div className="group text-left col-span-2">
                        <label htmlFor="url" className="block text-xl font-bold ml-2 mb-2 text-gray-700">Youtube ID</label>
                        <input id="url" {...register("url")} name="url" className="text-lg px-3 py-1 input input-primary rounded-2xl h-12" />
                        {errors.url && <span className="ml-2 text-brand-400 text-sm">{t("typeIdWorkLabel")}</span>}
                    </div>
                    <div className="group text-left col-span-2">
                        <label htmlFor="advantage" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("descriptionWorkLabel")}</label>
                        <textarea id="advantage" {...register("advantage", { required: true })} name="advantage" rows="4"
                            className="text-lg px-3 py-1 input input-primary rounded-2xl h-24" />
                        {errors.advantage && <span className="ml-2 text-brand-400 text-sm">{t("typeDescriptionWorkLabel")}</span>}
                    </div>
                </form>
            </div>
            <div className="flex justify-end py-5">
                <button disabled={loading} form="form" type="submit" className="px-14 py-3 btn btn-primary disabled:opacity-50">
                    {loading && <Loading />}
                    {!loading &&
                        <p className="font-medium text-center">
                            {t("saveWorkLabel")}
                        </p>
                    }
                </button>
            </div>
        </>
    );
};

export default WorkProgress;