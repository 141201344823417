import { Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';

const Modal = (props) => {
    const { shown, toggleModal, children, title, saved, ok, footer = true } = props;
    const { t } = useTranslation("lang");

    return (
        <>
            <Transition show={shown}
                enter="transition-opacity duration-300" enterFrom="opacity-0" enterTo="opacity-100"
                leave="transition-opacity duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="relative sm:w-1/2 mx-auto max-w-4xl max-h-xl h-auto my-auto">
                        {/*content*/}
                        <div className="border-0 rounded-4xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-center justify-between p-4 border-b border-solid">
                                <h3 className="text-3xl font-semibold ml-4">{title}</h3>
                                <button onClick={() => toggleModal(false)}
                                    className="p-2 text-black float-right text-3xl font-semibold outline-none focus:outline-none">
                                    ×
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto w-full">
                                {children}
                            </div>
                            {/*footer*/}
                            {footer && <div className="flex items-center justify-end p-6 border-t border-solid">
                                <button type="button" onClick={() => toggleModal(false)}
                                    className="text-brand-300 font-medium uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                                    {t("closeMessage")}
                                </button>
                                {!ok && <button type="button" onClick={saved}
                                    className="bg-brand-400 text-white active:bg-brand-500 font-medium uppercase text-sm px-6 py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                                    {t("saveMessage")}
                                </button>}
                            </div>}
                        </div>
                    </div>
                </div>
            <div className="opacity-20 fixed inset-0 z-30 bg-brand"></div>
            </Transition>
        </>
    );
}

export default Modal;