import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')


const setEcho = async () =>{
    const BACK_URL =process.env.REACT_APP_ECHO_BASE_URL;
    const wsHOST =process.env.REACT_APP_ECHO_HOST;
    const token = (await sessionStorage.getItem('token')).trim();

    return new Echo({
    broadcaster: 'pusher',
    key: '1234',
    id: '1234',
    cluster:'mt1',
    wsHost: wsHOST,
    wsPort: 6001,
    wssPort: 6001,
    authEndpoint : BACK_URL+'/broadcasting/auth',
    enabledTransports: ['ws', 'wss'],
    auth: {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: 'Bearer '+token
        },
     },
    })


}

export {
  setEcho
}