import React, { useState, useEffect } from "react";
import { apiInstance } from "services/instance";
import { toDate} from 'utils';
import { editFaqAsync } from "redux/redux";
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "components/shared";

const columns = [
  'Development',
  'Progress',
  'Created'
]

const Translate = () => {
  const [prog, setProg] = useState([]);
  const [show, setShow] = useState(false);
  const [des, setDes] = useState('');
  const [translate, setTranslate] = useState('');

  const dispatch = useDispatch();
  const trad = useSelector((state)=> state.langInfo);

  function editHandler (description){
    setShow(!show);
    setDes(description);
    console.log(description);
  }

  function submitHandler (e){
    setTranslate(e.target.value);
  }

  function updateHandler(){
    if(trad.data?.hasOwnProperty(des)){
      let idioma = JSON.parse(localStorage.getItem('jsonLang'));
      idioma[des] = translate;
      
      const data ={
        translation: idioma
      }
      console.log(data);
      dispatch(editFaqAsync({data}));
      setShow(!show);
    }
    return;
  }

  useEffect(()=>{
    const buildingProgress = JSON.parse(localStorage.getItem('buildData'));
    setProg(buildingProgress.data);
  }, [trad]);


  return (
    <>
      <section className="data justify-center align-center w-full card shadow-xl rounded-5xl p-2 sm:py-8 overflow-x-auto">
        <table className="table-auto w-full divide-y">
          <thead className="text-center">
            <tr className="table-row">
              {columns.map((name, i) => <th key={i} className="px-2 py-4">{name}</th>)}
            </tr>
          </thead>
          <tbody className="text-center divide-font-medium space-y-4 text-gray-800">
            {prog && prog.map((item, i)=>{
              return(
                <tr className="table-row hover:bg-brand-100 cursor-pointer" onClick={()=>{editHandler(item.description)}}>
                  <td>{item.real_state_development}</td>
                  <td>{item.description}</td>
                  <td>{toDate(item.created_at)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      <Modal saved={updateHandler} title={'Description translate'} shown={show} toggleModal={editHandler}>
        <div className="mb-9 mt-3">
          <p className="mb-3">Insert the transalation of:</p>
          <p>"{des}"</p>
        </div>
        <input
          required 
          value={translate}
          onChange={submitHandler} 
          id="progress" 
          type="text" 
          className="text-lg px-3 input input-primary h-14 rounded-2xl" />
      </Modal>
    </>
    
  );
};

export default Translate;
