import axios from 'axios';
import {
  configureStore,
  createReducer,
  createAsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
const baseURL = process.env.REACT_APP_BASE_URL

const initialState = {
  document: [],
  documentSucces:false,
  documentError:false,
  refuse: [],
  documentRefuse:false,
  documentApprove:false,
  approve:[],
  userDocuments:[],

  deedSuccess:false,
  deedError:false,
  urlDeed:[], 

  phases: [],

  langSuccess: true,
  langError: false,
  langInfo: [],

  editLangSuccess: true,
  editLangError: true,
  editLangInfo: []
};
//PROFILE
export const saveDocumentAsync = createAsyncThunk(
  'save/document',
  async ({selectedFile, deal_id, id}, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', selectedFile)
    formData.append('deal_id', deal_id);
    formData.append('document_type', 'comprobante')
    formData.append('document_type_id', id)
    try {
      const response = await axios.post(
        `${baseURL}/documents`,
        formData,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'accept': '/',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
          },
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const saveDeedAsync = createAsyncThunk(
  'save/deed',
  async ({selectedFile, id}, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', selectedFile)
    formData.append('deal_id', id)
    try {
      const response = await axios.post(
        `${baseURL}/documents/deed`,
        formData,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'accept': '/',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
          },
        },
      );
      return response.data;
    } catch (e) {
      console.log(e.response.data)
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const approveDocumentAsync = createAsyncThunk(
  'approve/document',
  async ({id}, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.post(
        `${baseURL}/documents/${id}/approve`,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const refuseDocumentAsync = createAsyncThunk(
  'refuse/document',
  async ({id}, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.post(
        `${baseURL}/documents/${id}/refuse`,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const userDocumentsAsync = createAsyncThunk(
  'user/documents',
  async ({id, deal_id}, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.get(
        `${baseURL}/documents/${deal_id}/${id}/user`,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const getDeedAsync = createAsyncThunk(
  'deed/user',
  async ({deal_id}, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.get(
        `${baseURL}/documents/deed/${deal_id}`,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'accept': '/',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
          },
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const getPhasesAsync = createAsyncThunk(
  'phases',
  async ({deal_stage}, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.get(
        `${baseURL}/deals/phases/${deal_stage}`,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const getLanguageAsync = createAsyncThunk(
  'lang',
  async (thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.get(
        `${baseURL}/multilanguage/30`,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        },
      );
      localStorage.setItem("jsonLang", JSON.stringify(response.data.data));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const createLangAsync = createAsyncThunk(
  'lang/create',
  async (name_long, name_short, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.post(
        `${baseURL}/multilanguage`, 
        name_long, name_short, 
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const editFaqAsync = createAsyncThunk(
  'lang/edit',
  async ({data}, thunkAPI) => {
  
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.put(
        `${baseURL}/multilanguage/30`,
           data,
         {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const deleteLangAsync = createAsyncThunk(
  'lang/delete',
  async (id, thunkAPI) => {
    const token = await sessionStorage.getItem('token');
    try {
      const response = await axios.delete(
        `${baseURL}/multilanguage/${id}`,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const resetState = createAction('reset/state');
export const getReducer = createReducer(initialState, builder => {
  builder
    .addCase(saveDocumentAsync.fulfilled, (state, action) => {
      state.document = action.payload;
      state.documentSucces = true
    })
    .addCase(saveDocumentAsync.rejected, state => {
      state.documentSucces = false;
      state.documentError = true
    })
    .addCase(approveDocumentAsync.fulfilled, (state, action) => {
      state.approve = action.payload;
      state.documentApprove = true;
      state.documentRefuse = false;
    })
    .addCase(refuseDocumentAsync.fulfilled, (state, action) => {
      state.refuse = action.payload;
      state.documentApprove = false;
      state.documentRefuse = true;
    })
    .addCase(userDocumentsAsync.fulfilled, (state, action) => {
      state.userDocuments = action.payload;
    })
    .addCase(getDeedAsync.fulfilled, (state, action) => {
      state.urlDeed = action.payload;
    })
    .addCase(getPhasesAsync.fulfilled, (state, action) => {
      state.phases = action.payload;
    })
    .addCase(resetState, state => {
      state.documentSucces = false;
      state.documentError = false
    })
    .addCase(saveDeedAsync.fulfilled, (state, action) => {
      state.documentSucces = true
    })
    .addCase(saveDeedAsync.rejected, (state, action) => {
      state.documentError = true
    })
    .addCase(getLanguageAsync.fulfilled, (state, action) => {
      state.langSuccess = true;
      state.langInfo = action.payload;
    })
    .addCase(getLanguageAsync.rejected, (state, action) => {
      state.langError = true
    })
    .addCase(editFaqAsync.fulfilled, (state, action) => {
      state.editLangSuccess = true;
      state.editLangInfo = action.payload;
    })
    .addCase(editFaqAsync.rejected, (state, action) => {
      state.editLangError = true
    })
});

export const store = configureStore({
  reducer: getReducer,
});
