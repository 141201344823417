// Required
import { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
// Others
import { bytesToSize } from '../../utils';
import { IoTrashBinOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const Dropzone = ({ setFiles, multiple = true }) => {
    const [actualFiles, setActualFiles] = useState([]);
    const { t } = useTranslation("lang");

    const onDrop = useCallback((acceptedFiles) => {
        setActualFiles([...actualFiles, ...acceptedFiles]);
        setFiles([...actualFiles, ...acceptedFiles]);
    }, [actualFiles, setFiles]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/jpeg, image/png', multiple });


    const removeFile = file => () => {
        const newFiles = [...actualFiles];
        newFiles.splice(newFiles.indexOf(file), 1);
        setActualFiles(newFiles);
    }

    const removeAll = () => {
        setActualFiles([]);
        setFiles([]);
    }

    const files = actualFiles.map((file, index) => (
        <div key={index} className="relative p-2 w-auto bg-brand-300 rounded-lg">
            {file.path.substring(0, 5)} - {bytesToSize(file.size)}

            <button type="button" onClick={removeFile(file)}
                className="absolute -right-3 -top-2 rounded-full bg-white px-2 z-50">x</button>
        </div>
    ));

    return (
        <section className="flex">
            <div {...getRootProps()} className="z-0 flex w-full gap-2 border-dashed border-2 border-brand-200 p-2 h-auto rounded-2xl bg-brand-50 cursor-pointer hover:bg-brand-100 transition ease-in-out">
                <input {...getInputProps()} />
                {actualFiles.length === 0 && <p className="text-sm font-medium text-brand">{t("dragAndDropLabel")}</p>}
                {files}
            </div>
            {files.length > 0 &&
                <button type="button" className="p-4 flex justify-center items-center" onClick={removeAll}>
                    <p className="text-2xl text-gray-700">
                        <IoTrashBinOutline />
                    </p>
                </button>}
        </section>
    )
}

export default Dropzone;