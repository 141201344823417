// Common
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// Others
import { apiInstance } from 'services/instance';
import { DataTable, Modal } from 'components/shared';
import { WorkProgress } from 'screens/admin';
import { toDate, swalConfirm, swalLoading, swalClose } from 'utils';
import { BsTrash } from 'react-icons/bs'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const WorkProgressList = () => {
    const [data, setData] = useState({});
    const [showCreate, setshowCreate] = useState(false);
    const { t } = useTranslation("lang");

    const trad = useSelector((state)=> state.langInfo);

    useEffect(() => {
        fetchProgresses();
    }, [trad]);

    const fetchProgresses = async (page) => {
        
        await apiInstance.get('/work-progress', { params: { page } })
            .then(({ data: res }) => {
                if( localStorage.getItem("lang") === "es"){
                    setData(parseData(res.items));
                    console.log(res.items);
                }
                else {  
                    for( let k in res.items.data){
                        if(trad.data?.hasOwnProperty(res.items.data[k].description)){
                            res.items.data[k].description = trad.data[res.items.data[k].description];
                        }
                    }
                    setData(parseData(res.items));
                    localStorage.setItem('buildData', JSON.stringify(data));
                }
            })
            .catch(console.log);
    }

    const handleSearch = async (search) => {
        await apiInstance.post('/work-progress/search', { query: search })
            .then(({ data: res }) => {
                setData(parseData(res.items));
            }).catch(console.log)
        return;
    };

    function parseData(items) {
        const rows = items.data.map((e) => {
            return {
                id: e.id,
                development: e.real_state_development,
                type: e.construction_type.type,
                department: e.department ?? e.tower,
                createdAt: toDate(e.created_at),
                description: e.description,
                details: {
                    icon: <BsTrash />,
                    action: 'clear'
                },
            };
        });

        localStorage.setItem("data_build", JSON.stringify(items));
        return ({ ...items, rows });
    };

    const handleCreate = () => {
        setshowCreate(!showCreate);
        fetchProgresses();
    };

    const handleDelete = async ({ id }) => {
        const MESSAGE = '¿Seguro que desea eliminar el avance de obra? No podras recuperarlo de nuevo';

        swalConfirm(MESSAGE, 'Aceptar').then((result) => {
            if (result.isConfirmed) {
                swalLoading('Elminando Avance de obra');
                apiInstance.delete(`/work-progress/${id}`)
                    .then(({ data: res }) => {
                        swalClose();
                        fetchProgresses();
                    }).catch(console.log);
            }
        });
    };

    return (
        <>
            <div className="h-auto bg-white rounded-4xl sm:rounded-5xl mx-8 shadow-lg p-2 sm:p-10">
                <h4 className="text-3xl font-bold text-center p-2 sm:p-0">{t("workProgressListLabel")}</h4>
                <div className="flex justify-between align-center">
                    {localStorage.getItem("lang") === "es" &&
                    <button onClick={handleCreate} className="p-2 rounded-xl bg-brand-50 hover:bg-brand-100 focus:outline-none transition">
                        <p className="uppercase text-sm text-brand-500 font-semibold">{t("createProgressLabel")}</p>
                    </button>}
                    {localStorage.getItem("lang") === "en" &&
                    <Link to="/translate">
                        <button onClick={""} className="p-2 rounded-xl bg-brand-50 hover:bg-brand-100 focus:outline-none transition">
                            <p className="uppercase text-sm text-brand-500 font-semibold">Translate Progresses</p>
                        </button>
                    </Link>}
                </div>
                <DataTable onSearch={handleSearch} placeholder={t("developmentOrApartmentPlaceholder")} onEvent={handleDelete}
                    columns={[t("developmentWorkListLabel"), t("typeWorkListLabel"), t("apartmentWorkListLabel"), t("createdWorkListLabel"), t("deleteWorkListLabel")]} data={data} onUpdate={(page) => fetchProgresses(page)} />
            </div>

            <Modal shown={showCreate} toggleModal={handleCreate} title={t("createProgressLabel")} footer={false}>
                <WorkProgress onEvent={handleCreate} />
            </Modal>
        </>
    );
};

export default WorkProgressList;