// Common
import { Menu, Transition } from '@headlessui/react'
import { Link, useHistory } from 'react-router-dom';
import { Fragment } from 'react'
// Icons
import { FaUser, FaDoorOpen, FaShieldAlt } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
// Others
import { logOut as logOff, swalConfirm, swal, swalLoading, swalClose } from 'utils';
import { apiInstance } from 'services/instance';
import { useTranslation } from 'react-i18next';

const Dropdown = ({ name }) => {
    const history = useHistory();
    const { t } = useTranslation("lang");

    const logOut = () => {
        const MESSAGE = t("logoutConfirmMessage");
        // const existing = document.getElementById('bitrixChat');

        swalConfirm(MESSAGE).then((result) => {
            if (result.isConfirmed) {
                swalLoading();
                apiInstance.post('/logout')
                    .then(() => {
                        // if (existing) {
                        //     document.getElementById('bitrixChat').remove();
                        // }
                        logOff();
                        swalClose();
                        // history.go(0);
                        history.push('/login');
                    }).catch((error) => {
                        swal('error', error.response.data.message);
                    });
            }
        });
    };

    return (
        <Menu as="div" className="relative inline-block text-left z-0" >
            <Menu.Button className="z-0 inline-flex items-center justify-center w-full py-1 px-3 text-sm font-medium text-brand rounded-md bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <div className="border-2 border-brand-light rounded-full h-8 w-8 flex items-center justify-center mr-2">
                    <FaUser />
                </div>
                <div className="flex items-center">
                    <p> {name ? name : t("profileLabel")} </p>
                    <IoIosArrowDown size={18} className="mx-2" />
                </div>
            </Menu.Button>
            <Transition as={Fragment}
                enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        <Menu.Item>
                            {({ active }) => (
                                <Link to="/profile" className={`focus:outline-none ${active ? 'bg-brand-400 text-white' : 'text-gray-900'}
                                        group flex rounded-md items-center w-full px-2 py-2 text-sm`} >
                                    <FaUser size="18" className="mx-2" />
                                    <p className="font-normal">{t("profileLabel")}</p>
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <Link to="/security" className={`focus:outline-none ${active ? 'bg-brand-400 text-white' : 'text-gray-900'}
                                        group flex rounded-md items-center w-full px-2 py-2 text-sm`} >
                                    <FaShieldAlt size="18" className="mx-2" />
                                    <p className="font-normal">{t("securityLabel")}</p>
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={() => logOut()} className={`focus:outline-none ${active ? 'bg-brand-400 text-white' : 'text-gray-900'}
                                    group flex rounded-md items-center w-full px-2 py-2 text-sm`} >
                                    <FaDoorOpen size="18" className="mx-2" />
                                    <p>{t("logoutLabel")}</p>
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default Dropdown;