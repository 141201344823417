// Common
import { Link, useLocation } from 'react-router-dom';

// Images
import Logo from 'assets/images/logos/Logo-idex-1.png';
// Other 
import { CgMenuRight } from 'react-icons/cg';
import { sideBarItemsEs, sideBarItemsEn, socials } from 'utils/data';
import { classNames } from 'utils';
import { useTranslation } from 'react-i18next';

let sideBarItems = [];

if(localStorage.getItem("lang") === "es"){
    sideBarItems = sideBarItemsEs;
}
else if (localStorage.getItem("lang") === "en"){
    sideBarItems = sideBarItemsEn;
}

const Sidebar = (props) => {
    const { active, toggleSidebar } = props;
    const { pathname: activeURL } = useLocation();
    const { t } = useTranslation("lang");
    const roles = JSON.parse(sessionStorage.getItem('roles'));

    const roleHandler = (routeRoles) => {
        let flag = false;
        roles.forEach((role) => flag = routeRoles?.includes(role) ? true : false);
        return flag;
    };

    const menuOptions = sideBarItems.map((e, index) =>
        <Link key={index} to={{ pathname: e.path }} target={e.type === 'link' && '_blank'}>
            <button className={classNames(!roleHandler(e.roles) ? 'hidden' : activeURL === e.path ? "bg-brand-light text-white" : "bg-white text-gray-800",
                "text-left px-6 py-3 my-1 w-full rounded-lg hover:bg-brand-50 transition focus:bg-brand-light focus:text-white focus:outline-none")}>
                <div className="flex space-x-2 items-center">
                    {e.icon}<p className="font-medium">{e.title}</p>
                </div>
            </button>
        </Link>
    );

    const socialURLS = socials.map(({ url, icon, style }, i) =>
        <a key={i} href={url} target="_blank" rel="noreferrer"
            className={`${style} w-8 h-8 rounded-full text-white flex justify-center items-center`}>
            <p className="text-xl">{icon}</p>
        </a>
    );

    return (
        <div className="bg-white h-full space-y-1 p-2 shadow-lg relative">
            <button className={active ? "uppercase font-bold text-gray-800 float-right p-1" : "hidden"}
                type="button" onClick={() => toggleSidebar()}><CgMenuRight size={26} />
            </button>
            <Link to={roles.includes('root') ? '/users' : '/home'} >
                <img src={Logo} className="h-auto py-5 px-2" alt="IDEX Logo" />
            </Link>
            {menuOptions}
            
            {/* <a href="https://b24-n6a35d.bitrix24.site/" target="_blank" rel="noreferrer"
            className= "text-gray-800 text-left px-6 py-3 flex">
            <p>Guía de usuario</p>
            </a> */}
        
            <div className="footer w-full h-1/6 absolute bottom-0 inset-x-0 flex flex-col items-center justify-center gap-3">
                <p className="text-sm text-center px-5">
                    {t("followUsLabel")}
                </p>
                <div className="flex gap-2 flex-wrap justify-center">
                    {socialURLS}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
