import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

const Loading = ({ type, color, width, height }) => {
    return (
        <div className="" align="center">
            <Loader type={ type ?? 'ThreeDots' } color={ color ?? '#FFF' } secondaryColor="#F7A8A1"
                height={ height ?? 30 } width={ width ?? 60 } timeout={100000} />
        </div>
    );
};

export default Loading;