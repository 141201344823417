import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import mex from "../../assets/images/Icons/bandera.png"
import usa from "../../assets/images/Icons/estados-unidos.png"
import trd from "../../assets/images/Icons/traduccion.png";

const LangSelector = () => {
  const { i18n } = useTranslation("lang");
  const [lng, setLng] = useState(false);

  const toggleLang = () => {
    setLng(!lng);
  };

  const changeLang = (language) => {
    localStorage.setItem("lang", language);
    i18n.changeLanguage(language);
    window.location.reload();
  };

  return (
    <div className="absolute bottom-5" style={{right:270}}>
      <div
        className="btn btn-primary p-3 rounded-full"
        style={{ borderRadius: 60 }}
        onClick={toggleLang}
      >
        <a>
          <img 
          src={trd}
          style={{
            width: 20,
            height: 20}}/>
        </a>
      </div>
      {lng && (
        <ul
          className="absolute primary-color p-2 right-14 font-semibold my-select"
          style={{
            width: 70,
            borderRadius: 20,
            bottom:-40
          }}
        >
          <li style={{display: "flex", justifyContent: "center"}}>
            <a onClick={()=> changeLang("es")} >
              <img 
                src={mex}
                style={{
                  width: 30,
                  height: 30,
                  marginBottom: 10,
                  borderRadius:30,
                  borderWidth:1,
                  borderColor: "white"}}/>
            </a>
          </li>
          <li style={{display: "flex", justifyContent: "center"}}>
            <a onClick={()=> changeLang("en")} >
              <img 
                src={usa}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius:30,
                  borderWidth:1,
                  borderColor: "white"}}/>
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default LangSelector;