import { toCurrency, classNames } from 'utils';

const ClientDestails = ({ user }) => {
    // console.log(user);

    return (
        <>
            <div className="grid grid-cols-3 gap-4">
                <div className="group">
                    <h4 className="font-bold ml-2 my-2">ID Cliente Neodata</h4>
                    <p className="px-4 py-1 bg-gray-300 rounded-full">{user.profile.neodata_customer_id ?? '---'}</p>
                </div>
                <div className="group">
                    <h4 className="font-bold ml-2 my-2">Nombre Cliente</h4>
                    <p className="px-4 py-1 bg-gray-300 rounded-full">{user.name + ' ' + user.profile?.last_name}</p>
                </div>
                <div className="group">
                    <h4 className="font-bold ml-2 my-2">Ubicacion</h4>
                    <p className="px-4 py-1 bg-gray-300 rounded-full">{user.payments[0]?.department ?? '---'}</p>
                </div>
            </div>
            <table className="table-auto w-full divide-y">
                <thead className="text-center">
                    <tr className="table-row">
                        <th className="p-2 sm:py-4 mx-2">Referencia</th>
                        <th className="p-2 sm:py-4">Monto</th>
                        <th className="p-2 sm:py-4"># Mercado Pago</th>
                        <th className="p-2 sm:py-4"># Autorizacion</th>
                        <th className="p-2 sm:py-4">Fecha aprobacion</th>
                        <th className="p-2 sm:py-4">Status</th>
                    </tr>
                </thead>
                <tbody className="text-center divide-y text-gray-700 font-medium">
                    {user.payments?.map((e, i) => {
                        return (
                            <tr key={i} className="table-row">
                                <td className="text-sm">{e.reference}</td>
                                <td className="">{toCurrency(parseFloat(e.amount))}</td>
                                <td className="">{e.mercadopago_order ?? '---'}</td>
                                <td className="">{e.authorization_code ?? '---'}</td>
                                <td className="">{e.approved_date ?? '---'}</td>
                                <td>
                                    <p className={classNames(e.status === 'approved' ? 'bg-green-200' : e.statues === 'pending' ? 'bg-yellow-500' : 'bg-red-300', 'm-1 p-1 rounded-full text-sm')}>
                                        {e.status}
                                    </p>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default ClientDestails;