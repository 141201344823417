import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { userDocumentsAsync } from "redux/redux";
import { useDispatch } from "react-redux";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

const ClientDeals = ({ shown, toggleModal, user, toggle, setDeal, stateDocument }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("lang");
  const handleDispatch = (id)=>{
    setDeal(id)
    setTimeout(() => {
      toggle()
      dispatch(userDocumentsAsync({id:user.id, deal_id:id}))
    }, 200);
  }

  return (
    <>
      <Transition
        show={shown}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={`z-10 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none ${stateDocument?"opacity-20":"opacity-100"}`}>
          <div className="relative sm:w-1/2 mx-auto max-w-4xl max-h-xl h-auto my-auto">
            {/*content*/}
            <div className={"pb-10 border-0 rounded-4xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"}>
              <table className="table-auto w-full divide-y rounded-2xl  my-10 mx-auto pb-20 h-40">
                <thead className="text-center my-10">
                  <tr className="table-row text-ms sm:text-lg">
                    <th className="p-1 sm:py-4 flex ml-5">
                      {t("developmentNameLabel")}{" "}
                      <button
                        className="mx-2 absolute top-2 right-2 sm:top-5 sm:right-5 bold"
                        onClick={() => toggleModal(false)}
                      >
                        <AiOutlineCloseCircle size={30} />
                      </button>
                    </th>
                  </tr>
                  <h3>{t("pickDevelopmentLabel")}</h3>
                </thead>
                <tbody className="text-center text-gray-700 font-medium ">
                  {user?.deals?.map((e, i) => (
                    <tr key={i}>

                          <th className="py-2">
                          <button
                            onClick={()=>handleDispatch( e.id )}
                            className={"border-2 border-black p-0.5 m-1 font-bold text-xs sm:text-sm rounded "}
                          >
                            {e.real_state_development }{'     '}{`     ${e.real_state_development}_${e.tower_acronym}_${e.tower}_${e.floor}`}
                          </button>
                          </th>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="opacity-20 fixed inset-0 z-30 bg-gray-900"></div>
      </Transition>
    </>
  );
};

export default ClientDeals;
