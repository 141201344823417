// Common 
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// Components
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Loading } from 'components/shared';
import { FaUser } from 'react-icons/fa';
// Others
import { apiInstance } from 'services/instance';
import { swal, updateInfo } from 'utils';

import ReactGA from "react-ga"
import { useTranslation } from 'react-i18next';

ReactGA.initialize("UA-221463012-1")

const Profile = () => {
    const history = useHistory();
    const { t } = useTranslation("lang");

    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(JSON.parse(sessionStorage.getItem('info')).profile);

    useEffect(() => {
        setUserInfo(JSON.parse(sessionStorage.getItem('info')).profile);
    }, []);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const updateProfile = async (form) => {
        // form.birthdate = form.birthdate.toISOString().split('T')[0];
        form.birthdate = userInfo.birthdate;

        await apiInstance.put(`/profile/${userInfo.id}`, form)
            .then(async ({ data: res }) => {
                await updateInfo(JSON.stringify(res.items));
                swal('success', 'Su perfil ha sido actualizado');
                history.push('/home');
            }).catch((error) => {
                swal('error', error.response.data.message);
            });
        setLoading(false);
    };

    const onSubmit = (data) => {
        setLoading(true);
        updateProfile(data);
    };

    return (
        <>
            <div className="bg-white rounded-5xl mx-8 shadow-lg p-10 text-center">
                <div className="picture">
                    <h1 className="text-2xl font-bold">{t("profileTitleLabel")}</h1>
                    <div className="items-center justify-center flex rounded-full h-36 w-36 sm:h-40 sm:w-40 mx-auto my-6 border-2 border-brand-light">
                        <div className="w-4/5 flex justify-center">
                            <FaUser size={80} />
                        </div>
                    </div>

                    <form id="form" onSubmit={handleSubmit(onSubmit)} className="form sm:px-10 grid lg:grid-cols-2 gap-6 my-10">
                        <div className="group text-left">
                            <label htmlFor="name" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("nameProfileLabel")}</label>
                            <input id="name" defaultValue={userInfo.name} {...register("name", { required: true })}
                                type="text" readOnly className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                            {errors.name && <span className="ml-2 text-brand-400 text-sm">{t("enterNameLabel")}</span>}
                        </div>
                        <div className="group text-left">
                            <label htmlFor="lastname" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("lastNameProfileLabel")}</label>
                            <input id="lastname" defaultValue={userInfo.last_name} {...register("last_name", { required: true })}
                                type="text" readOnly className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                            {errors.lastname && <span className="ml-2 text-brand-400 text-sm">{t("enterLastNameLabel")}</span>}
                        </div>
                        <div className="group text-left col-span-2">
                            <label htmlFor="email" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("emailProfileLabel")}</label>
                            <input id="email" defaultValue={userInfo.email} {...register("email", { required: true })}
                                type="email" className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                            {errors.email && <span className="ml-2 text-brand-400 text-sm">{t("enterEmailLabel")}</span>}
                        </div>
                        <div className="group text-left col-span-2">
                            <label htmlFor="phone" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("phoneProfileLabel")}</label>
                            <input id="phone" defaultValue={userInfo.phone} {...register("phone", { required: true })}
                                type="number" className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                            {errors.phone && <span className="ml-2 text-brand-400 text-sm">{t("enterPhoneLabel")}</span>}
                        </div>
                        {/* <div className="group text-left">
                            <label className="block text-xl font-bold ml-2 mb-2 text-gray-700">Nacimiento</label>
                            <Controller control={control} name="birthdate" defaultValue={new Date(`${userInfo.birthdate}T00:00:00`)}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <DatePicker onChange={onChange} onBlur={onBlur} selected={value} maxDate={new Date()}
                                        dateFormat="dd, MMM yyyy" wrapperClassName="w-full" className="text-lg px-3 input input-primary h-14 rounded-2xl w-full" />
                                )} />
                            {errors.birthdate && <span className="ml-2 text-brand-400 text-sm">Proporcione su fecha de nacimiento</span>}
                        </div> */}
                    </form>

                </div>
                <button disabled={loading} form="form" type="submit" className="mx-auto px-14 py-3 btn btn-primary disabled:opacity-50">
                    {loading && <Loading />}
                    {!loading && <p className="w-full font-medium text-center">{t("saveProfileLabel")}</p>}
                </button>
            </div>
        </>
    );
};

export default Profile;