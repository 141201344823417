import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
    const { i18n } = useTranslation("lang");

    useEffect(() => {
        const storedValue = localStorage.getItem("lang");
        if (!storedValue) {
            i18n.changeLanguage(navigator.language.slice(0,2));
            if( i18n.changeLanguage(navigator.language.slice(0,2)) !== "es"){
                i18n.changeLanguage("en");
            }
        }
        i18n.changeLanguage(storedValue);
    }, []);

    return (
        <Router>
            <Routes/>
        </Router>
    );
}

export default App;