// Common
import { useEffect, useState } from 'react';
// Others
import { apiInstance } from 'services/instance';
import { BiFileFind } from 'react-icons/bi';
import { AiFillFilePdf } from "react-icons/ai";
import ClientDetails from 'components/ClientDetails';
import { DataTable, Modal } from 'components/shared';
import { useHistory } from 'react-router-dom';
import ClientDocuments from './ClientDocuments';
import ClientDeals from './ClientDeals';
import { useTranslation } from 'react-i18next';

const Clients = () => {
    const [deal, setDeal]= useState('')
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalDocument, setShowModalDocument] = useState(false);
    const [showModalDocuments, setShowModalDocuments] = useState(false);
    const [user, setUser] = useState({});
    const { t } = useTranslation("lang");

    useEffect(() => {
        fetchClients();
    }, []);

    const handleModal = (client) => {
        setUser(client);
        setShowModal(!showModal);
    };
    const handleModalDocument = (client) => {
        setUser(client);
        setShowModalDocument(!showModalDocument);
    };
    const handleModalDocuments = () => {
        setShowModalDocuments(!showModalDocuments);
    };
    const fetchClients = async (page) => {
        await apiInstance.get('/users', { params: { user: 'customer', page } })
            .then(({ data: res }) => {
                setData(parseData(res.items));
            }).catch(console.log);
    };

    const handleSearch = async (search) => {
        await apiInstance.post('/search-user', { query: search }, { params: { user: 'customer' } })
            .then(({ data: res }) => {
                setData(parseData(res.items));
            }).catch(console.log);
    };

    const handleAction = ({ action, item, page }) => {
        action === 'details' ? handleModal(item) : action === "document" ? handleModalDocument(item) : handleDelete(item.id, page);
    };

    const handleDelete = async (id, page) => {
        await apiInstance.delete(`/users/${id}`)
            .then(({ data: res }) => {
                fetchClients(page);
            }).catch(console.log);
    };

    function parseData(items) {
        const rows = items.data.map((e) => {
            return {
                id: e.id,
                name: e.name + ' ' + e.profile.last_name.split(' ')[0],
                email: e.email,
                phone: e.profile.phone,
                development: e.deals[0].real_state_development,
                active: e.deleted_at ? false : true,
                documents: {
                    icon: <BiFileFind />,
                    action: 'details'
                },
                details: {
                    icon: <AiFillFilePdf/>,
                    action: 'document'
                },
                delete: {
                    action: 'delete'
                }
            };
        });

        return ({ ...items, rows });
    }

    return (
        <>
            <div className="h-auto w-full bg-white rounded-5xl mx-auto shadow-lg sm:p-10 relative">
                <section className="sm:mb-10 py-4 sm:p-0">
                    <h4 className="text-3xl font-bold text-center">{t("clientsLabel")}</h4>
                </section>
                <DataTable onEvent={handleAction} onSearch={handleSearch}
                    columns={[t("nameHeaderLabel"), t("emailHeaderLabel"), t("phoneHeaderLabel"), t("developHeaderLabel"), '', t("docsHeaderLabel"), t("statusHeaderLabel")]} data={data} onUpdate={(page) => fetchClients(page)} />
            </div > 
            <Modal shown={showModal} toggleModal={handleModal} title="Detalle Clientes" saved={() => (0)} >
                {user && <ClientDetails user={user} />}
            </Modal>
            <ClientDeals shown={showModalDocument} stateDocument={showModalDocuments} setShown={setShowModalDocument} toggleModal={handleModalDocument} user={user} toggle={handleModalDocuments} setDeal={setDeal}/>
            <ClientDocuments shown={showModalDocuments} toggleModal={handleModalDocuments} deal={deal} user={user}/>
        </>
    );
};

export default Clients;
