// Common
import Swal from 'sweetalert2';
// Images
import Brasilia from 'assets/images/logos/Logo-Brasilia.png';
import Anuva from 'assets/images/logos/Logo-Anuva.png';
import Aladra from 'assets/images/logos/Logo-Aladra.png';
import Liva from 'assets/images/logos/Logo-Liva.png';
import Citi from 'assets/images/logos/Logo-Bosque-Capital.png'
import Deimare from 'assets/images/logos/Logo-Deimare.png'
import Construjal from 'assets/images/logos/Logo-Vista-Bosque.png'

import GenericBanner from 'assets/images/banners/Banner-brasilia.jpeg';
import BannerAnuva from 'assets/images/banners/Banner-anuva.png';
import BannerBCapital from 'assets/images/banners/Banner-Bosque-Capital.png';
import BannerDeimare from 'assets/images/banners/Banner-Deimare.png';
import BannerConstrujal from 'assets/images/Banner-Vista-Bosque.png';

// Constants
const TOKEN_KEY = 'token';
const USER_KEY = 'info';
const USER_ROLE = 'roles';
const USER_NOTIFIED = 'notified';

export const getLogo = (development) => {
	switch (development.toLowerCase()) {
		case 'brasilia': return (Brasilia);
		case 'anuva': return (Anuva);
		case 'aladra': return (Aladra);
		case 'liva': return (Liva);
		case 'bosque capital': return (Citi);
		case 'deimare': return (Deimare);
		case 'vista bosque': return (Construjal);
		default: break;
	}
};

export const getBanner = (development) => {
	switch (development.toLowerCase()) {
		case 'brasilia': return (GenericBanner);
		case 'anuva': return (BannerAnuva);
		case 'bosque capital': return (BannerBCapital);
		case 'deimare': return (BannerDeimare);
	    case 'vista bosque': return (BannerConstrujal);
		default: return (GenericBanner);
	}
};

export const getAccount = (development) => {
	switch (development.toLowerCase()) {
		case 'brasilia': return '';
		case 'anuva': return ['65-50725502-0', 'Santander', '014320655072550201', 'NACIONES UNIDAS CAPITAL SAPI DE CV'];
		case 'aladra': return ['0267861450201', 'Banbajio', '030320900020079227', 'LODI RESIDENCIAL SAPI DE CV'];
		case 'bosque capital': return ['50-00029574-1', 'Santander', '014320500002957411', 'CITIDESARROLLOS VERTICAL SAPI DE CV'];
		case 'deimare': return ['65-50914991-2', 'Santander', '014320655091499123', 'TOWERS RESIDENCE GDL SAPI DE CV'];
		case 'vista bosque': return ['65-50985300-2', 'Santander', '014320655098530027', 'OPERADORA CONSTRUJAL SA DE CV'];
		case 'liva': return '';
		default: break;
	}
};

export const swal = (type, msg) => {
	const TITLE = type === 'error' ? 'Oops...' : type === 'success' ? 'Confirmado!' : type;
	Swal.fire(TITLE, msg, type);
};

export const swalConfirm = (msg, confirm, cancel) => {

	let lang = {}

	if (localStorage.getItem("lang") === "es"){
		lang = {
			Close: "Cerrar",
			Cancel: "Cancelar"
		}
	} else {
		lang = {
			Close: "Close",
			Cancel: "Cancel"
		}
	}

	const OPS = {
		title: msg,
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: confirm ?? `${lang.Close}`,
		confirmButtonColor: '#F4857B',
		cancelButtonText: cancel ?? `${lang.Cancel}`,
	};

	return Promise.resolve(Swal.fire(OPS));
};

export const swalLoading = (text) => {

	let lang = {}

	if(localStorage.getItem("lang") === "es"){
		lang = {
			wait: "Por favor espere..",
			saving: "Guardando datos de sesión"
		}
	} else {
		lang = {
			wait: "Please wait..",
			saving: "Saving data sesion"
		}
	}

	Swal.fire({
		title: `${lang.wait}`,
		html: text ?? `${lang.saving}`,
		allowEscapeKey: false,
		allowOutsideClick: false,
		didOpen: () => {
			Swal.showLoading();
		},
	});
};

export const swalClose = () => {
	Swal.close();
};

export const login = (token, info, roles) => {
	sessionStorage.setItem(TOKEN_KEY, token);
	sessionStorage.setItem(USER_KEY, info);
	sessionStorage.setItem(USER_ROLE, roles);
};

export const updateInfo = (info) => {
	sessionStorage.setItem(USER_KEY, info);
};

export const logOut = () => {
	sessionStorage.removeItem(TOKEN_KEY);
	sessionStorage.removeItem(USER_KEY);
	sessionStorage.removeItem(USER_ROLE);
	sessionStorage.removeItem(USER_NOTIFIED);
};

export const isLoggedIn = () => {
	if (sessionStorage.getItem(TOKEN_KEY)) {
		return true;
	}
	return false;
};

export const toCurrency = (num) => {
	return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const arrayComparison = (a, b) => {
	return (
		(a.length === b.length) && a.sort()
			.every((value, index) => value === b.sort()[index])
	);
}

export const classNames = (...classes) => {
	return classes.filter(Boolean).join(' ');
}

export const bytesToSize = (bytes) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const futurePayment = (upcoming, amount) => {

	let lang = {}

	if(localStorage.getItem("lang") === "es"){
		lang = {
			late: "atrasado",
			paid: "pagado",
			pending: "pendiente"
		}
	} else {
		lang = {
			late: "late",
			paid: "paid",
			pending: "pending"
		}
	}

	const status = (new Date() >= new Date(upcoming)) && amount > 0 ? `${lang.late}` :
		(new Date() <= new Date(upcoming)) && amount > 0 ? `${lang.pending}` : `${lang.paid}`;

	const available = (new Date() >= new Date(upcoming)) && (status !== ("paid" || "pagado"));

	return { available, status };
};

export const toDate = (date) => {
	const formated = new Date(date);
	if(localStorage.getItem("lang") === "es"){
		date = formated.toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });
	}
	else{
		date = formated.toLocaleDateString('en-EN', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });
	}

	return date;
};