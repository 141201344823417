// Common
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// Components
import Loading from "components/shared/Loading";
// Others
import { useCreditInfo } from "hooks/useCreditInfo";
import { getBanner, getLogo, toCurrency } from "utils";
import ReactGA from "react-ga";
import '../components/shared/CarouselEscrituration/styles.css'
import { useTranslation } from "react-i18next";
ReactGA.initialize("UA-221463012-1");

const EscriturationDocuments = () => {
  const { name, index, deal_id, type } = useParams();
  const {
    items: { card_info: cardInfo },
    areLoading,
  } = useCreditInfo(name, index);
  const [info, setInfo] = useState([]);
  const [creditChart, setCreditChart] = useState({});
  const { t } = useTranslation("lang");

    useEffect(() => {
      const infoHandler = () => {
        let dev, credit, paid, toPay, late, date;

        dev = cardInfo?.department_development;
        credit = toCurrency(parseFloat(cardInfo?.deposit));
        paid = toCurrency(cardInfo?.total_customer_paid);
        toPay = toCurrency(cardInfo?.deposit - cardInfo?.total_customer_paid);
        late = cardInfo?.total_late_payments;
        date = new Date(cardInfo?.next_payment_date.replace(/-/g, "/"));
        date = date.toLocaleDateString("es-MX", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
      });

      setInfo([dev, credit, paid, toPay, late, date]);
    };

    if (!areLoading) {
      infoHandler(cardInfo);
      cChart(
        cardInfo.total_late_payments,
        cardInfo.total_customer_paid,
        cardInfo.deposit - cardInfo.total_customer_paid
      );
    }
  }, [areLoading, cardInfo]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const cChart = (late, paid, toPay) => {
    setCreditChart({
      labels: ["Atrasado", "Adeudo", "Pagado"],
      datasets: [
        {
          data: [late, toPay, paid],
          backgroundColor: ["#F4857B", "#FFD791", "#4CB5AE"],
          borderWidth: 4,
        },
      ],
    });
  };

  return (
    <>
      {areLoading && (
        <div className="flex justify-center items-center h-96">
          <Loading color="#F4857B" type="Oval" width={150} height={150} />
        </div>
      )}
      {!areLoading && (
        <div className="bg-white rounded-5xl shadow-lg z-0 pb-10">
          <div className="flex sm:h-96 relative w-full">
            <img
              src={cardInfo && getBanner("")}
              className="object-cover object-bottom w-full rounded-4xl"
              alt="Logo"
            />
            <div className="absolute w-full top-24 sm:top-52 items-center justify-center">
              <div className="mx-auto items-center justify-center flex rounded-full h-36 w-36 sm:h-56 sm:w-56 bg-white">
                <div className="items-center justify-center flex rounded-full h-32 w-32 sm:h-52 sm:w-52 mx-auto bg-gray-200">
                  <div className="w-4/5">
                    <img
                      src={cardInfo && getLogo(cardInfo.department_development)}
                      className="w-full"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="data justify-center shadow-lg w-full rounded-4xl align-center w-full p-6 mt-10 sm:m-0 sm:p-10">
            <h4 className="text-3xl font-bold text-center my-2">
              {t("deedPhasesLabel")}
            </h4>
            <h4 className="text-3xl font-regular text-center my-5 mb-20">
              {type === 'HIPOTECARIO'? t("mortgageLabel"):'Contado'}
            </h4>
          </div>
         <div className='flex flex-col shadow-lg  rounded-5xl  my-10 mx-3 sm:mx-10 pb-20'>
          <h3 className="text-2xl sm:text-4xl font-bold text-center my-2  py-5 text-white rounded-tr-3xl rounded-tl-3xl py-10 bg-brand">Check List</h3>
          <Link to={{ pathname: `/Documents/general/${deal_id}` }} className='mx-5 bg-brand sm:mx-20 rounded-3xl py-5 text-center text-white my-5'>
            <button  className="text-2xl sm:text-3xl font-regular text-center my-2">General</button>
          </Link>
          <Link to={{ pathname: `/Documents/fisica/${deal_id}` }} className='mx-5 bg-brand sm:mx-20 rounded-3xl py-5 text-center text-white my-5'>
          <button className="text-2xl sm:text-3xl font-regular text-center my-2">{t("salariedIndividualButton")}</button>
          </Link>
          <Link to={{ pathname: `/Documents/business/${deal_id}` }} className='mx-5 bg-brand sm:mx-20 rounded-3xl py-5 text-center text-white my-5'>
          <button className="text-2xl sm:text-3xl font-regular text-center my-2">{t("individualWithBusinessButton")}</button>
          </Link>
          <Link to={{ pathname: `/Documents/moral/${deal_id}` }} className='mx-5 bg-brand sm:mx-20 rounded-3xl py-5 text-center text-white my-5'>
            <button className="text-2xl sm:text-3xl font-regular text-center my-2">{t("corporateEntityButton")}</button>
          </Link>
         </div>
         {/* <div className='flex flex-col shadow-2xl  rounded-5xl  my-10 mx-20 py-20'>
          <h3 className="text-4xl font-bold text-center my-2  py-5  rounded-tr-3xl rounded-tl-3xl py-10">Carga de documentos</h3>
          
          <div class="image-upload">
            <label for="file-input">
              <img className="w-1/2 mx-auto" src={postDocument} alt="document" />
            </label>
            <input id="file-input" type="file"onChange={changeHandler}/>
          </div>

            {isFilePicked?<h1 className="mx-auto text-2xl">{selectedFile.length} {selectedFile.length===1?'documento seleccionado':'documentos seleccionados'} </h1>:null}

            <button disabled={!isFilePicked} onClick={handleSubmission} className={documentSucces?"w-1/2 flex my-auto items-center justify-center align-center bg-blue-400 mx-auto rounded-3xl py-5 text-center text-white my-5 text-3xl font-regular":!isFilePicked?"w-1/3 bg-brand-100 mx-auto rounded-3xl py-5 text-center text-white my-5 text-3xl font-regular text-center my-2":"w-1/3 bg-brand mx-auto rounded-3xl py-5 text-center text-white my-5 text-3xl font-regular text-center my-2"}>
              {documentSucces?"Enviado":documentError?'error, vuelve a intentarlo':!isFilePicked?"Selecciona un documento": "Enviar"}
              {documentSucces?<img src={like} className="w-1/5" alt="like"/>:null}
            </button>
          </div> */}
        </div>
      )}
    </>
  );
};

export default EscriturationDocuments;
