// Common
import React from "react";
import ReactDOM from 'react-dom';
import './index.css';
// Others
import reportWebVitals from './reportWebVitals';
import App from './App';
import {store} from './redux/redux';
import {Provider} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
// Languages
import './i18n';

const Root = () => {
    return (
        <React.StrictMode>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnHover
                />
            <Provider store={store}>
            <App/>
            </Provider>
        </React.StrictMode>
    );
};

ReactDOM.render(<Root />,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
