// Common
import { useState, useEffect } from 'react';
import { Navbar, Sidebar } from 'components';
// Others
import { FaWhatsapp } from 'react-icons/fa';
import { classNames } from 'utils';
import { getLanguageAsync, deleteLangAsync, editFaqAsync, createLangAsync } from 'redux/redux';
import { useDispatch, useSelector } from 'react-redux';

import ReactGA from "react-ga"

ReactGA.initialize("UA-221463012-1")

const DashboardContainer = ({ children }) => {
    const [sidebar, setSidebar] = useState(false);
    const whatsapp = 'https://api.whatsapp.com/send?phone=523327491125&text=Buen%20día%2C%20Me%20gustaría%20conocer%20los%20proyectos%20de%20vivienda%20disponibles..';
    const idexUser = !JSON.parse(sessionStorage.getItem('info')).deals.length; 
    const dispatch = useDispatch(); 
    
    // useEffect(() => {
    //     const existing = document.getElementById('bitrixChat');

    //     if (!existing && !idexUser) {
    //         const script = document.createElement('script');
    //         script.type = 'text/javascript';
    //         script.id = 'bitrixChat';
    //         document.body.appendChild(script);

    //         script.onload = ((w, d, u) => {
    //             var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
    //             var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
    //         })(window, document, 'https://intranet.idex.cc/upload/crm/site_button/loader_2_lw4fcy.js');
    //     }
    // }, [idexUser])

    useEffect(() => {
        dispatch(getLanguageAsync());
        // dispatch(editFaqAsync({data}));
        ReactGA.pageview(window.location.pathname + window.location.search);
    });


    return (
        <div className="bg-gray-100 h-screen flex relative">
            <div className={classNames(sidebar ? 'w-3/5 absolute md:relative' : 'hidden', 'md:block md:w-2/12 z-30 h-full')}>
                <Sidebar active={sidebar} toggleSidebar={() => setSidebar(!sidebar)} />
            </div>

            <div className="w-full md:w-10/12 overflow-y-auto">
                <Navbar toggleSidebar={() => setSidebar(!sidebar)} />
                <div className="max-w-screen-lg mx-auto">
                    <div className="z-10 p-4 sm:p-10">
                        {children}
                    </div>
                </div>
            </div>

            {!idexUser && <div className="absolute right-6 bottom-6 p-2 bg-green-600 bg-opacity-10 rounded-full">
                <a href={whatsapp} target="_blank" rel="noreferrer" className="flex relative focus:outline-none bg-green-500 w-12 h-12 rounded-full">
                    <div className="flex w-full h-full text-white text-3xl justify-center items-center">
                        <FaWhatsapp />
                    </div>
                    <div className="animate-ping border border-green-600 absolute h-full w-full rounded-full opacity-50" />
                </a>
            </div>}
            {/* <LangSelector/> */}
        </div>
    );
};

export default DashboardContainer;
