import React, { useState, useRef } from "react";

import { Link } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Slider from "react-slick";
import Logo1 from "assets/images/proccess/thumbnail_image002.png";
import Logo2 from "assets/images/proccess/thumbnail_image003.png";
import Logo3 from "assets/images/proccess/thumbnail_image004.png";
import Logo4 from "assets/images/proccess/thumbnail_image005.png";
import Logo5 from "assets/images/proccess/thumbnail_image006.png";
import Logo6 from "assets/images/proccess/thumbnail_image007.png";
import Logo7 from "assets/images/proccess/thumbnail_image008.png";
import "./Number.css";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const CarouselEscrituration = ({ setNumber, name, index, type, deal_id, numberPhase }) => {
  const { t } = useTranslation("lang");

  const data =
    type !== "HIPOTECARIO"
      ? [
          {
            id:1,
            title: t("buildingProcessLabel"),
            link: "/proccess",
            imageUrl: Logo1,
          },
          {
            id:2,
            title: t("docsUpdateLabel"),
            link: `/escriturationDocuments/${name.toLowerCase()}/${index}/${deal_id}/${type}`,
            imageUrl: Logo2,
          },
          {
            id:3,
            title: t("deedRegistratedLabel"),
            link: "/Deed",
            imageUrl: Logo3,
          },
          {
            id:4,
            title: t("scheduledDeliverLabel"),
            link: "/delivery",
            imageUrl: Logo4,
          },
          {
            id:5,
            title: t("deliverySuccessLabel"),
            link: "/deliverySuccess",
            imageUrl: Logo5,
          },
        ]
      : [
          {
            id:1,
            title: t("buildingProcessLabel"),
            link: "/proccess",
            imageUrl: Logo1,
            active:true
          },
          {
            id:2,
            title: t("docsUpdateLabel"),
            link: `/escriturationDocuments/${name.toLowerCase()}/${index}/${deal_id}/${type}`,
            imageUrl: Logo2,
            active:false
          },
          {
            id:3,
            title: t("approvedCreditLabel"),
            link: `/Autorization`,
            imageUrl: Logo6,
            active:true
          },
          {
            id:4,
            title: t("deedRegistrationProcessLabel"),
            link: `/EscriturationProcess/${deal_id}`,
            imageUrl: Logo7,
          },
          {
            id:5,
            title: t("deedRegistratedLabel"),
            link: "/Deed",
            imageUrl: Logo3,
          },
          {
            id:6,
            title: t("scheduledDeliverLabel"),
            link: "/delivery",
            imageUrl: Logo4,
          },
          {
            id:7,
            title: t("deliverySuccessLabel"),
            link: "/deliverySuccess",
            imageUrl: Logo5,
          },
      ];
  const ref = useRef();
  const PrevArrow = ()=>{
    return (
      imgIndex !== 0?(
        <button
          onClick={() =>  ref.current.slickPrev()}
          className="custom-prevArrow"
        >
          <FaArrowLeft size={35} />
        </button>
      ):(
        null
      )
    );
  } 
  const NextArrow = ()=>{
    return (
      imgIndex !== last ?(
        <button
          onClick={() => ref.current.slickNext()}
          className="custom-nextArrow"
        >
          <FaArrowRight size={35} />
        </button>
      ):(null)
    );
  } 
  const [imgIndex, setImgIndex] = useState(1);
  const [last, setLast] = useState(null);
  const [current, setCurrent] = useState(0)
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    ref: ref,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    beforeChange: (current, next) => (setImgIndex(next), setNumber(next+1)),
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  useEffect(() => {
    if (type === "HIPOTECARIO") {
      setLast(6);
    } else {
      setLast(4);
    }
    if(type==="CONTADO"){
      switch (numberPhase) {
        case 1:
          setCurrent(1)
          break;
        case 2:
          setCurrent(2)
          break;
        case 3:
          setCurrent(2)
          break;
        case 4:
          setCurrent(2)
          break;
        case 5:
          setCurrent(2)
          break;
        case 6:
          setCurrent(3)
          break;
        case 7:
          setCurrent(3)
          break;
        case 8:
          setCurrent(4)
          break;
        case 9:
          setCurrent(5)
          break;
        default:
          setCurrent(1)
          break;
      }
    }
    else{
      switch (numberPhase) {
        case 1:
          setCurrent(1)
          break;
        case 2:
          setCurrent(2)
          break;
        case 3:
          setCurrent(3)
          break;
        case 4:
          setCurrent(3)
          break;
        case 5:
          setCurrent(4)
          break;
        case 6:
          setCurrent(5)
          break;
        case 7:
          setCurrent(5)
          break;
        case 8:
          setCurrent(6)
          break;
        case 9:
          setCurrent(7)
          break;
        default:
          setCurrent(1)
          break;
      }
    }
      if(current){
        ref.current.slickGoTo(current-1)
      }
  }, [type, current]);
  return (
    <div className="container">
      <Slider {...settings}>
        {data.map((img, idx) => (
          idx===last && imgIndex===0 || idx===last-1 && imgIndex===0 || idx === 0 && imgIndex === last?(
            <div
              className={`slide md:h-96 h-94 w-full`}
              >
            </div>
          ):(
            <div
              className={
              idx === imgIndex
                ? `slide activeSlide rounded shadow-lg shadow-black rounded-2xl md:h-96 h-94  px-10 md:px-0 md:w-full border border-gray-100 ${idx+1>current?"filter saturate-50 brightness-90":""}`
                : `slide rounded shadow-lg shadow-black rounded-2xl md:h-96 h-94 px-10 md:px-5 border border-gray-100 ${idx+1>current?"filter saturate-50 brightness-90":""}`
              }
              >
              <div className="flex justify-center items-center">
                <h1
                  className={
                    "text-l font-semi-bold text-center my-2 w-full h-6 text-gray-800 md:text-2xl"
                  }
                >
                  {img.title}
                </h1>
              </div>
              <div className="flex justify-center items-center p-10">
                <img src={img.imageUrl} alt={index} className="image" />
              </div>
              <Link
                disabled={idx+1>current}
                to={{ pathname: idx+1>current?'' : img.link }}
                className="flex sm:w-1/3 flex justify-center items-center"
              >
                <button disabled={idx+1>current} className={`mx-auto px-10 sm:px-2 md:w-full sm:w-1/3 py-2 ${idx+1>current?'bg-gray-300':'bg-brand'}  rounded-full text-white focus:outline-none focus:ring focus:ring-offset-2 hover:bg-brand-light focus:ring-brand focus:ring-opacity-50 active:bg-brand-dark`}>
                  <div className="flex space-x-2 items-center justify-center">
                    <FaArrowRight size={28} />
                  </div>
                </button>
              </Link>
            </div>
          )
        ))}
      </Slider>
    </div>
  );
};

export default CarouselEscrituration;
