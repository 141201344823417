import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichText = ({ onChange, onBlur, value }) => {
    const [parsedText, setParsedText] = useState(value || null);

    useEffect(() => {
        if (value) setParsedText(value);
    }, [value]);

    const handleChange = (value) => {
        setParsedText(value)
        onChange(value);
    };

    return (
        <div onBlur={onBlur}>
            <ReactQuill theme="snow" value={parsedText} onChange={handleChange}
                className="rounded-2xl border-2 border-gray-800" />
        </div>
    );
}

export default RichText;
