// Common
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// Components
import Loading from 'components/shared/Loading';
// Others
import { useCreditInfo } from 'hooks/useCreditInfo';
import { classNames, getBanner, getLogo, toCurrency, toDate } from 'utils';
import { Doughnut } from 'react-chartjs-2';
import { FaArrowRight, FaLanguage } from 'react-icons/fa';
import Illustration from 'assets/images/Working.svg';
import Illustration1 from 'assets/images/proccess/thumbnail_image001.png';
import ReactGA from "react-ga"
import { useTranslation } from 'react-i18next';
//

ReactGA.initialize("UA-221463012-1")


const Details = () => {
    const { name, index } = useParams();
    const { items: { card_info: cardInfo }, areLoading } = useCreditInfo(name, index);

    const [info, setInfo] = useState([]);
    const [creditChart, setCreditChart] = useState({
        labels:[],
        datasets:[]
    });

    const { t } = useTranslation("lang");
    
    useEffect(() => {
        const infoHandler = () => {
            let dev, credit, paid, toPay, late, date;

            dev = cardInfo.department_development;
            credit = toCurrency(parseFloat(cardInfo.deposit));
            paid = toCurrency(cardInfo.total_customer_paid_month);
            toPay = toCurrency(cardInfo.deposit - cardInfo.total_customer_paid_month);
            late = cardInfo.total_late_payments;
            if (cardInfo.next_payment_date != "") {
                date = new Date(cardInfo.next_payment_date.replace(/-/g, '/'));
                if (localStorage.getItem("lang") === "es"){
                    date = date.toLocaleDateString('es-MX', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
                }
                else if (localStorage.getItem("lang") === "en"){
                    date = date.toLocaleDateString('en-EU', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
                }
            }
            setInfo([dev, credit, paid, toPay, late, date]);
        };

        if (!areLoading) {
            infoHandler(cardInfo);
            cChart(cardInfo.total_late_payments, cardInfo.total_customer_paid_month, cardInfo.deposit - cardInfo.total_customer_paid_month);
        }
    }, [areLoading, cardInfo]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const cChart = (late, paid, toPay) => {
        if(localStorage.getItem("lang") == "es"){
            setCreditChart({
                labels: ['Atrasado', 'Adeudo', 'Pagado'],
                datasets: [{
                    data: [late, toPay, paid],
                    backgroundColor: ['#F4857B', '#FFD791', '#4CB5AE'],
                    borderWidth: 4
                }]
            });
        } else if (localStorage.getItem("lang") == "en"){
            setCreditChart({
                labels: ['Late', 'Debt', 'Paid'],
                datasets: [{
                    data: [late, toPay, paid],
                    backgroundColor: ['#F4857B', '#FFD791', '#4CB5AE'],
                    borderWidth: 4
                }]
            });
        }
    };
    return (
        <>

            {areLoading && <div className="flex justify-center items-center h-96">
                <Loading color="#F4857B" type="Oval" width={150} height={150} />
            </div>}
            {!areLoading &&
                <div className="bg-white rounded-5xl shadow-lg z-0">
                    <div className="flex sm:h-96 relative w-full">
                        <img src={cardInfo && getBanner(cardInfo.department_development)} className="object-cover object-bottom w-full rounded-4xl" alt="Logo" />
                        <div className="absolute w-full top-24 sm:top-52 items-center justify-center">
                            <div className="mx-auto items-center justify-center flex rounded-full h-36 w-36 sm:h-56 sm:w-56 bg-white">
                                <div className="items-center justify-center flex rounded-full h-32 w-32 sm:h-52 sm:w-52 mx-auto bg-gray-200">
                                    <div className="w-4/5">
                                        <img src={cardInfo && getLogo(cardInfo.department_development)} className="w-full" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="data justify-center align-center w-full p-6 mt-10 sm:m-0 sm:p-10">
                        <h4 className="text-3xl font-bold text-center my-2">{info[0]}</h4>
                        <div className="sm:flex shadow-lg w-full rounded-4xl px-6 sm:px-16 py-2 sm:py-4 justify-between text-center sm:text-justify">

                            <div className="font-medium text-gray-500 uppercase">
                                <p>{t("apartmentLabel")}: <span className="text-gray-800">{cardInfo.department}</span></p>
                                <p>{t("dueDateLabel")}: <span className="text-gray-800">{cardInfo.delivery_date ? toDate(cardInfo.delivery_date) : ''}</span></p>
                                <p>{t("prototypeLabel")}: <span className="text-gray-800">{cardInfo.prototype}</span></p>
                                <p>{t("dimensionsLabel")}: <span className="text-gray-800">{cardInfo.measure} m2</span></p>
                            </div>

                            <div className="font-medium text-gray-500 uppercase">
                                <p>{t("PriceLabel")}: <span className="text-gray-800">{toCurrency(parseFloat(cardInfo.department_price))}</span></p>
                                <p>{t("downPaymentLabel")}: <span className="text-gray-800">{toCurrency(parseFloat(cardInfo.deposit))}</span></p>
                                <p>{t("salesAdvisorLabel")}: <span className="text-gray-800">{cardInfo.sales_advisor}</span></p>
                                <p>{t("paymentOnDeliveryLabel")}: <span className="text-gray-800">{toCurrency(parseFloat(cardInfo.department_price - cardInfo.deposit))}</span></p>
                            </div>
                        </div>

                        <div className="details grid sm:grid-cols-2 gap-8">
                            <div className="card shadow-xl rounded-3xl p-4 sm:p-8 w-full">
                                <div className="sm:flex justify-between items-center">
                                    <h4 className="text-2xl font-semibold">{t("myAccountLabel")}</h4>
                                    <h4 className="text-xl font-semibold text-gray-800">{info[1]}</h4>
                                </div>
                                <div className="w-60 sm:w-full mb-2 sm:p-4">
                                    {<Doughnut data={creditChart} options={{ responsive: true }} />}
                                </div>
                                <div className="sm:flex justify-between items-center">
                                    <div className="font-medium text-gray-500 uppercase leading-6">
                                        <p>{t("paidLabel")}: <span className="text-green-500 font-semibold">{info[2]}</span></p>
                                        <p>{t("debtLabel")}: <span className="text-black font-semibold">{info[3]}</span></p>
                                        <p>{t("latePaymentsLabel")}: <span className={classNames(info[4] > 0 ? 'text-brand-light' : 'text-green-500')}>{toCurrency(info[4] ?? 0)}</span></p>
                                    </div>
                                    <div className="w-full sm:w-1/3">
                                        <Link to={`/credit/${cardInfo.department_development.toLowerCase()}/${index}`}>
                                            <button className="transition w-full py-2 bg-brand rounded-full text-white focus:outline-none focus:ring focus:ring-offset-2 hover:bg-brand-light focus:ring-brand focus:ring-opacity-50 active:bg-brand-dark">
                                                <p className="flex items-center justify-center">
                                                    <FaArrowRight size={30} />
                                                </p>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="font-medium text-gray-500 uppercase flex justify-between mt-4 text-sm leading-4">
                                    <p>{t("nextPaymentLabel")}: </p>
                                    <span className="text-right font-semibold text-black">{info[5]}</span>
                                </div>
                            </div>

                            <div className="w-full card shadow-xl rounded-3xl p-4 sm:p-8 flex flex-col justify-between">
                                <h4 className="text-2xl font-bold">{t("buildingProgressLabel")}</h4>
                                <img src={Illustration} alt="Logo" />
                                <Link to={{ pathname: `/progress/${name}/${cardInfo.department}` }} className="flex w-full">
                                    <button className="mx-auto w-full sm:w-1/2 py-2 bg-brand rounded-full text-white focus:outline-none focus:ring focus:ring-offset-2 hover:bg-brand-light focus:ring-brand focus:ring-opacity-50 active:bg-brand-dark">
                                        <div className="flex space-x-2 items-center justify-center">
                                            <FaArrowRight size={30} />
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full sm:w-3/5 card shadow-xl  rounded-3xl p-1 sm:p-8 flex flex-col justify-items-center space-x-1/2 m-auto ">
                                <h4 className="text-2xl font-bold">{t("propertyRegistrationLabel")}</h4>
                                <img src={Illustration1} alt="Logo" />
                                <Link to={{ pathname: `/escrituration/${name.toLowerCase()}/${index}/${cardInfo?.payment_method}/${cardInfo.deal_id}/${cardInfo.stage}` }} className="flex w-full">
                                    <button className="mx-auto w-full sm:w-1/3 py-2 bg-brand rounded-full text-white focus:outline-none focus:ring focus:ring-offset-2 hover:bg-brand-light focus:ring-brand focus:ring-opacity-50 active:bg-brand-dark">
                                        <div className="flex space-x-2 items-center justify-center">
                                            <FaArrowRight size={30} />
                                        </div>
                                    </button>
                                </Link>
                             </div>
                        </div>
                </div>}
        </>
    );
};

export default Details;
