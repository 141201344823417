import React, { useState, useEffect } from "react";
import { BsUpload } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import postDocument from "../../assets/images/load.png";
import {
  resetState,
  saveDeedAsync,
  userDocumentsAsync,
} from "redux/redux";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "@headlessui/react";
import { apiInstance } from "services/instance";
import { useTranslation } from "react-i18next";

const ClientDocuments = ({ shown, toggleModal, deal, user}) => {
  const {documentSucces, documentError, userDocuments} = useSelector((state)=>state)
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState({});
  const [approve, setApprove] = useState(false)
  const [refuse, setRefuse] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { t } = useTranslation("lang");
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };
  const handleSubmission = () => {
    dispatch(saveDeedAsync({id:deal, selectedFile: selectedFile }));
  };
  useEffect(() => {
    if(refuse||approve||documentSucces){
      setTimeout(() => {
        dispatch(resetState())
        setSelectedFile({})
        setIsFilePicked(false)
        setApprove(false)
        setRefuse(false)
        dispatch(userDocumentsAsync({id:user.id, deal_id:deal}))
      }, 100);
    }
  }, [approve, refuse, documentSucces]);
  useEffect(() => {
    if(documentError){
      setTimeout(() => {
        dispatch(resetState())
        setSelectedFile({})
        setIsFilePicked(false)
        setApprove(false)
        setRefuse(false)
        dispatch(userDocumentsAsync({id:user.id, deal_id:deal}))
      }, 4000);
    }
  }, [documentError]);
  const approveDocument = async (id) => {
    await apiInstance.post(`/documents/${id}/approve`)
        .then(({ data: res }) => {
            setApprove(true)
        }).catch((error)=>{
          setApprove(false)
        });
  };
  const refuseDocument = async (id) => {
    await apiInstance.post(`/documents/${id}/refuse`)
        .then(({ data: res }) => {
            setRefuse(true)
        }).catch((error)=>{
          setRefuse(false)
        });
  };
  console.log(userDocuments?.items?.length)
  return (
    <>
      <Transition
        show={shown}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="z-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="overflow-x-auto relative sm:w-full mx-auto max-w-4xl max-h-xl h-auto my-auto">
            {/*content*/}
            <div className={userDocuments?.items?.length===0 || userDocuments?.items === undefined?"pb-60 border-0 rounded-4xl pb-10 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none":userDocuments?.items?.length>5?"pb-10 border-0 rounded-4xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none":"pb-20 border-0 rounded-4xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"}>
              <div className={`absolute right-0 ${userDocuments?.items?.length===0 || userDocuments?.items === undefined?'sm:right-20 mr-3':'sm:right-10'} sm:pl-10 top-20 w-3/12 mt-5`}>
                <div className="">
                  <div class="image-upload">
                    <label for="file-input">
                      <img
                        className="w-full mx-auto my-2"
                        src={postDocument}
                        alt="document"
                      />
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept=".pdf"
                      onChange={changeHandler}
                    />
                  </div>
                  {isFilePicked?<h3 className="text-xs">{selectedFile.name}</h3>:null}
                  <button
                    disabled={documentSucces}
                    onClick={handleSubmission}
                    className={documentSucces?"flex my-auto items-center justify-center align-center bg-gray-200 mx-auto px-4 rounded-xl py-2 text-center text-white my-3 text-xs font-regular":"flex my-auto items-center justify-center align-center bg-brand mx-auto px-4 rounded-xl py-2 text-center text-white my-3 text-xs font-regular"}
                  >
                    {documentSucces?'Enviado':documentError? t("errorMessageLabel"): t("sendMesageLabel")}
                  </button>
                </div>
              </div>
              <table className="table-auto w-full divide-y rounded-2xl  my-10 mx-1 sm:mx-5 pb-60 h-40">
                <thead className="text-center my-10">
                  <tr className="table-row text-xs sm:text-lg">
                    <th className="p-1 sm:py-4 mx-2">{t("documentationNameLabel")}</th>
                    <th className="p-1 sm:py-4">{t("downloadSectionLabel")}</th>
                    <th className="p-1 sm:py-4">{t("checkListSectionLabel")}</th>
                    <th className="p-1 sm:py-4 flex ">
                    {t("documentDeedLabel")}{" "}
                      <button
                        className="mx-2 absolute top-2 right-2 sm:top-5 sm:right-5 bold"
                        // eslint-disable-next-line no-unused-expressions
                        onClick={() => {toggleModal(false), dispatch(resetState())}}
                      >
                        <AiOutlineCloseCircle size={30} />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center text-gray-700 font-medium">
                  {userDocuments?.items?.map((e, i) => (
                    <tr key={i}>
                      <td className="text-xs sm:text-lg text-shadow p-0">{e.name}</td>
                      <td className="mx-auto items-center justify-center align-center">
                        <a href={`https://api-portalclientes.idex.cc/storage/${e.path}`} target="_download" rel="noopener noreferrer" download>
                          <button className="bg-gray-300 shadow-lg border-black p-1 sm:m-1 font-bold text-sm rounded-3xl">
                            <BsUpload />
                          </button>
                        </a>
                      </td>
                        <td>
                        <div className="flex flex-row">
                          <button
                            onClick={()=>approveDocument(e.id)}
                            className={e.status === 'aprobado'?"border-2 border-black p-0.5 m-1 font-bold text-xs sm:text-sm rounded bg-green-600":"border-2 border-black p-0.5 m-1 font-bold text-xs sm:text-sm rounded"}
                          >
                            {t("approvedButtonButton")}
                          </button>
                          <button
                            onClick={()=>refuseDocument(e.id)}
                            className={e.status === 'rechazado'?"border-2 border-black p-0.5 m-1 font-bold text-xs sm:text-sm rounded bg-red-500":"border-2 border-black p-0.5 m-1 font-bold text-xs sm:text-sm rounded"}
                          >
                            {t("rejectedSectionButton")}
                          </button>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="opacity-20 fixed inset-0 z-30 bg-gray-900"></div>
      </Transition>
    </>
  );
};

export default ClientDocuments;
