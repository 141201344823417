import { useState, useEffect } from 'react';
import { apiInstance } from 'services/instance';

export const useCreditInfo = (development, index) => {
    const [items, setItems] = useState({});
    const [areLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await apiInstance.get('/card-info-credit')
                .then(({ data: res }) => {
                    console.log(res.items);
                    development ?
                        setItems(res.items
                            .filter(({ card_info: { department_development: dev } }) =>
                                dev.toLowerCase() === development.toLowerCase())[0]) :
                        setItems(res.items);
                }).catch(console.log);
            setLoading(false);
        };

        fetchData();
    }, [development, index]);

    return { items, areLoading };
};
