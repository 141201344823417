// Icons
import {
    FaFileAlt, FaQuestionCircle, FaUserAlt, FaRegBuilding, FaUserCheck,
    FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube, FaTasks
} from 'react-icons/fa';

const sideBarItemsEs = [
    {
        path: '/home',
        title: 'Mi cuenta',
        icon: <FaFileAlt />,
        roles: ['cliente']
    },
    {
        path: '/users',
        title: 'Usuarios IDEX',
        icon: <FaUserCheck />,
        roles: ['root', 'admin']
    },
    {
        path: '/clients',
        title: 'Clientes',
        icon: <FaUserAlt />,
        roles: ['root', 'admin']
    },
    // {
    //     path: '/work-progress',
    //     title: 'Avances de obra',
    //     icon: <FaRegBuilding />,
    //     roles: ['root', 'admin']
    // },
    {
        path: '/work-progress',
        title: 'Avances de Obra',
        icon: <FaRegBuilding />,
        roles: ['root', 'admin']
    },
    {
        path: '/faq',
        title: 'Dudas',
        icon: <FaQuestionCircle />,
        roles: ['root', 'admin', 'cliente']
    },
    {
        type: 'link',
        path: 'https://b24-n6a35d.bitrix24.site/',
        title: 'Guía de usuario',
        icon: <FaTasks />,
        roles: ['root', 'admin', 'cliente']
    },
    {
        type: 'link',
        path: 'https://noticiasidex.bitrix24.site/',
        title: 'Noticias IDEX',
        icon: <FaTasks />,
        roles: ['root', 'admin', 'cliente']
    },
];

const sideBarItemsEn = [
    {
        path: '/home',
        title: 'My account',
        icon: <FaFileAlt />,
        roles: ['cliente']
    },
    {
        path: '/users',
        title: 'IDEX users',
        icon: <FaUserCheck />,
        roles: ['root', 'admin']
    },
    {
        path: '/clients',
        title: 'Clients',
        icon: <FaUserAlt />,
        roles: ['root', 'admin']
    },
    // {
    //     path: '/work-progress',
    //     title: 'Avances de obra',
    //     icon: <FaRegBuilding />,
    //     roles: ['root', 'admin']
    // },
    {
        path: '/work-progress',
        title: 'Building progress',
        icon: <FaRegBuilding />,
        roles: ['root', 'admin']
    },
    {
        path: '/faq',
        title: 'FAQ',
        icon: <FaQuestionCircle />,
        roles: ['root', 'admin', 'cliente']
    },
    {
        type: 'link',
        path: 'https://b24-n6a35d.bitrix24.site/',
        title: 'User guide',
        icon: <FaTasks />,
        roles: ['root', 'admin', 'cliente']
    },
    {
        type: 'link',
        path: 'https://noticiasidex.bitrix24.site/',
        title: 'IDEX news',
        icon: <FaTasks />,
        roles: ['root', 'admin', 'cliente']
    },
];

const socials = [
    { icon: <FaFacebookF />, style: 'bg-blue-900', url: 'https://www.facebook.com/idexinmobiliaria/' },
    { icon: <FaInstagram />, style: 'bg-gradient-to-tr from-yellow-400 via-red-400 to-pink-500', url: 'https://www.instagram.com/idexinmobiliaria/' },
    { icon: <FaLinkedinIn />, style: 'bg-blue-600', url: 'https://www.linkedin.com/company/idex-inmobiliaria/' },
    { icon: <FaTwitter />, style: 'bg-blue-400', url: 'https://twitter.com/idexmx' },
    { icon: <FaYoutube />, style: 'bg-red-600', url: 'https://www.youtube.com/user/idexmx' },
];

export { sideBarItemsEs, sideBarItemsEn, socials };
