// Common
import React, { useEffect, useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import postDocument from '../assets/images/postDocument.png'
import like from '../assets/images/like.png'
import { useDispatch, useSelector } from "react-redux";
import { resetState, saveDocumentAsync } from "redux/redux";
import { apiInstance } from "services/instance";
import { Loading } from "components/shared";
import { useTranslation } from "react-i18next";


const Documents = () => {
  const { name, deal_id } = useParams();
  const { t } = useTranslation("lang");
  const {documentSucces, documentError} = useSelector((state)=>state)
  const dispatch = useDispatch()
  const [data, setData] = useState({});
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const trad = useSelector( state => state.langInfo);
  
  	const changeHandler = (event) => {
  		setSelectedFile(event.target.files[0]);
  		setIsFilePicked(true);
  	};
    useEffect(()=>{
      if(documentSucces || documentError){
        setTimeout(() => {
          dispatch(resetState())
          setIsFilePicked(false);
          setSelectedFile([]);
          getDocuments()
        }, 4000);
      }
    },[documentSucces, documentError])
    const handleSubmission = (id) => {
      dispatch(saveDocumentAsync({selectedFile:selectedFile, deal_id:deal_id, id}))
    };
    const getDocuments = async () => {
      await apiInstance.get(`/documents/${name}/${deal_id}/myDocuments`)
        .then((res) => {
          if(localStorage.getItem("lang") === "es"){
            setData(res.data);
            console.log(res.data.items);
          }else{
            res.data.items?.map((item)=>{
              if( trad.data.hasOwnProperty(item.name)){
                item.name = trad.data[item.name];
                item.description = trad.data[item.description];
              }
            })
            setData(res.data);
          }
        }).catch((e) => {
          console.log('er', e.response)
        });
    };
    useEffect(()=>{
      if(deal_id){
        getDocuments()
      }
    },[trad])
  return (
    <>
      {
        data?.items === undefined?(
          <Loading color="#F4857B" type="Oval" width={150} height={150} />
        ):(
          <div className="flex flex-col shadow-lg  rounded-5xl  my-10 mx-3 sm:mx-5 pb-60">
            <h3 className="text-2xl sm:text-4xl font-bold text-center my-12 bg-brand py-5 text-white rounded-tr-3xl rounded-tl-3xl py-14">
              {name === "general"
                ? "general"
                : name === "administracion"
                ? t("salariedIndividualLabel")
                : name === "business"
                ? t("individualWiithBusinessLabel")
                : t("legalEntityLabel")}
            </h3>
            { data?.items?.map((e, i) => (
              <Disclosure key={i}>
                {({ open }) => (
                  <div className="w-full sm:w-1/2 sm:mx-auto border-b-2 border-brand mt-5">
                    <Disclosure.Button className=" rounded-xl flex justify-between items-center w-full px-4 py-2 text-sm font-medium  focus-visible:ring-opacity-75 transition">
                      <span className="text-base sm:text-lg text-left">{e.name}</span>
                      {open ? (
                        <FiChevronUp size={25} color="#E43535" />
                      ) : (
                        <FiChevronDown size={25} color="#E43535" />
                      )}
                    </Disclosure.Button>
                    <Transition
                      enter="transition-opacity duration-150"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pt-4 pb-2  text-gray-600">
                        <div className="richtext text-xs sm:text-base">{e.description}</div>
                        {
                          e.documents.length!==0?
                          null
                          :
                          (
                            <div className='flex flex-row rounded-5xl items-center'>
                              <div class="image-upload">
                                <label for="file-input">
                                  <img className="w-1/2 mx-auto rounded-2xl" src={postDocument} alt="document" />
                                </label>
                                <input id="file-input" type="file"onChange={changeHandler}/>
                              </div>
                              <div className="flex flex-col">
                                {isFilePicked?<h1 className="mx-auto text-sm text-center">{selectedFile.length} {selectedFile.length===1?'documento seleccionado':'documento seleccionado'} </h1>:null}
                                <button disabled={!isFilePicked} onClick={()=>handleSubmission(e.id)} className={documentSucces?"w-full flex my-auto items-center justify-center align-center bg-blue-400 mx-auto rounded-3xl py-3 text-center text-white my-5 text-base font-regular":!isFilePicked?"w-full bg-brand-100 mx-auto rounded-3xl py-3 text-center text-white my-5 text-sm px-3 font-regular text-center my-2":"w-full bg-brand mx-auto rounded-3xl px-3 py-3 text-center text-white my-3 text-sm font-regular text-center"}>
                                  {documentSucces?"Enviado":documentError?'error, vuelve a intentarlo':!isFilePicked?t("pickAFileLabel"): "Enviar"}
                                  {documentSucces?<img src={like} className="w-1/6" alt="like"/>:null}
                                </button>
                              </div>
                            </div>
                          )
                        }
                      </Disclosure.Panel>
                    </Transition>
                  </div>
                )}
              </Disclosure>
            ))}
          </div>
        )
      }
    </>
  );
};

export default Documents;
