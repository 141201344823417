import { BsSearch } from 'react-icons/bs';
import { Paginator } from 'components/shared';
import { classNames } from 'utils';
import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

const DataTable = ({ onSearch, columns = [], data = {}, onEvent, onUpdate, placeholder }) => {
    const { rows = [], total, from, to, last_page: last, current_page: current } = data; 
    let timer;

    const { t } = useTranslation("lang");

    const keyPressed = () => {
        clearTimeout(timer);
    }

    const keyReleased = (term) => {
        // Prevent errant multiple timeouts from being generated
        clearTimeout(timer);
        timer = setTimeout(() => {
            onSearch(term?.toLowerCase());
        }, 500);
    }

    const handleItem = ({ action, email, page, id }) => {
        if (action !== 'clear') {
            const item = (data.data.find(searched => searched.email === email));
            onEvent({ action, item, page });
        } else {
            onEvent({ id });
        }
    };

    const Switcher = ({ checked, data, disabled }) => {
        return (
            <>
                <Switch disabled={disabled} checked={checked} onChange={() => handleItem(data)}
                    className={`${checked ? 'bg-brand-400' : 'bg-gray-300'
                        } relative inline-flex items-center h-5 rounded-full w-10 transition-colors focus:outline-none disabled:opacity-60`}>
                    <span className={`${checked ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-3 h-3 transform bg-white rounded-full transition-transform`} />
                </Switch>
                <p className="text-xs">{checked ? t("activeStatusLabel") : t("disabledStatusLabel")}</p>
            </>
        )
    };

    const Details = ({ icon, data, disabled }) => {
        return (
            <button disabled={disabled} onClick={() => handleItem(data)}
                className="p-2 focus:outline-none disabled:opacity-60">
                <p className="text-xl">{icon}</p>
            </button>
        );
    };

    return (
        <>
            <div className="relative sm:py-6 px-8">
                <input type="text" placeholder={`${t("searchByLabel")}${placeholder ?? t("nameOrEmailLabel")}`} onKeyPress={keyPressed} onKeyUp={(e) => keyReleased(e.target.value)}
                    className="mt-4 input border-0 bg-brand-50 rounded-xl input-primary placeholder-brand-200 placeholder-font-bold px-10" />
                <p className="absolute left-12 text-brand-200 top-1/2">
                    <BsSearch />
                </p>
            </div>
            <section className="data justify-center align-center w-full card shadow-xl rounded-5xl p-2 sm:py-8 overflow-x-auto">
                <table className="table-auto w-full divide-y">
                    <thead className="text-center">
                        <tr className="table-row">
                            {columns.map((name, i) => <th key={i} className="px-2 py-4">{name}</th>)}
                        </tr>
                    </thead>
                    <tbody className="text-center divide-font-medium space-y-4 text-gray-800">
                        {rows.length ? rows.map((row, i) =>
                            <tr key={i} className={row?.active === false ? 'text-gray-400' : 'table-row'}>
                                {Object.values(row).slice(1).map((e, j) => {
                                    const reference = e?.action ? { action: e?.action, email: row.email, page: current, id: row.id } : null;
                                    // console.log(typeof e !== 'boolean');
                                    const isRoot = row.email === 'root@idex.cc';
                                    // console.log(isRoot)
                                    return typeof e !== 'boolean' ? (
                                        <td key={j} className={classNames(e?.icon ? 'hover:bg-brand-100 cursor-pointer' : '', 'p-2')}>
                                            {e?.action ? (e?.action === 'delete' ?
                                                <Switcher disabled={isRoot} checked={row.active} data={reference} /> :
                                                <Details disabled={isRoot} icon={e.icon} data={reference} />) : e}
                                        </td>
                                    ) : null;
                                })}
                            </tr>
                        ) : <tr>
                            <td colSpan={columns.length}>
                                <p className="text-2xl text-center font-semibold text-gray-500 py-4">No se encontraron registros</p>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </section >
            <Paginator total={total} pages={{ from, to, current, last }} onChange={(e) => e !== current ? onUpdate(e) : null} />
        </>
    );
};

export default DataTable;