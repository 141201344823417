import Axios from 'axios';

export const apiInstance = Axios.create(
    {
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
);

apiInstance.interceptors.request.use(
    async config => {
        const token = await sessionStorage.getItem('token');
        if (token) { 
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => { return Promise.reject(error); }
)