// Common
import { useEffect, useState } from 'react';
// Others
import { apiInstance } from '../services/instance';
import { arrayComparison } from '../utils';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const RolesDetails = ({ update }) => {
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [actualRole, setActualRole] = useState('');
    const [permissionsInRole, setPermissionsInRole] = useState([]);

    useEffect(() => {
        fetchRoles();
        fetchPermissions();
    }, []);

    /**
     * Fetch Roles and Permissions from db
     */
    const fetchRoles = async () => {
        await apiInstance.get('/roles')
            .then(({ data: res }) => {
                setRoles(res.items);
            }).catch((error) => {
                console.log(error);
            });
    };

    const fetchPermissions = async () => {
        await apiInstance.get('/permissions')
            .then(({ data: res }) => {
                setPermissions(res.items.data);
            }).catch((error) => {
                console.log(error);
            });
    };

    /**
     * @param {string} element
     * Updates selected role to modify or read
     */
    const handleActualRole = (element) => {
        setActualRole(element);
        setPermissionsInRole(element.permissions.map((e) => {
            return e.name;
        }));
    }

    const allRoles = roles.map((e, index) => {
        return (
            <button key={index} onClick={() => handleActualRole(e)}
                className={classNames(actualRole.name === e.name ? "ring ring-offset-1 ring-brand" : "", "w-full btn bg-white px-3 py-2 focus:outline-none")}>
                <p className="text-gray-700 text-xs sm:text-sm">{e.name}</p>
            </button>
        );
    });

    /**
     * @param {object} element 
     * @returns new Array of permissions in Selected role, plus or less permissions clicked
     */
    const handlePermission = (element) => {
        if (permissionsInRole.includes(element.name)) {
            setPermissionsInRole(permissionsInRole.filter(e => e !== element.name));
            updateHandler(actualRole.permissions.map(e => e.name),
                permissionsInRole.filter(e => e !== element.name));
            return;
        }
        setPermissionsInRole([...permissionsInRole, element.name])
        updateHandler(actualRole.permissions.map(e => e.name),
            [...permissionsInRole, element.name]);
    }

    const allPermissions = permissions.map((e, index) => {
        return (
            <button disabled={!actualRole} key={index} onClick={() => handlePermission(e)}
                className={classNames(permissionsInRole.includes(e.name) ? "ring ring-offset-1 ring-brand" : "", "w-full btn bg-white px-3 py-2 focus:outline-none")}>
                <p className="text-gray-700 text-xs sm:text-sm">{e.name}</p>
            </button>
        );
    });

    const updateHandler = (desired, considered) => {
        update({
            id: actualRole.id,
            updated: !arrayComparison(desired, considered),
            form: {
                name: actualRole.name,
                permissions: considered.map((e) => { return { name: e } })
            } 
        });
    };

    return (
        <section className="grid grid-cols-2 gap-5">
            <div className="bg-gray-200 rounded-lg p-3 overflow-y-auto max-h-80">
                <div className="relative my-4">
                    <h4 className="text-center text-brand font-medium">Rol</h4>
                    {/* <button className="p-2 text-brand absolute right-5 top-0 focus:outline-none">
                        <FiPlusCircle size={18} />
                    </button> */}
                </div>
                <div className="roles my-2 space-y-3 py-2 px-2 sm:px-4">
                    {allRoles}
                </div>
            </div>
            <div className="bg-gray-200 rounded-lg p-3 overflow-y-auto max-h-80">
                <div className="relative my-4">
                    <h4 className="text-center text-brand font-medium">Permisos</h4>
                </div>
                <div className="permissions my-2 space-y-3 py-2 px-2 sm:px-4">
                    {allPermissions}
                </div>
            </div>
        </section>
    );
};

export default RolesDetails;