// Common
import { Redirect, Switch, Route } from 'react-router-dom';
// Layout
import { Login, DashboardContainer } from 'screens/layout';
// Client Screens
import { Autorization, EscriturationProccess, Home, Faq, Deal, Profile, Security, Credit, Progress, Escrituration, Proccess, Deed, Delivery, DeliverySuccess, EscriturationDocuments, Documents} from 'screens';
// Admin Screens
import { Clients, Users, WorkProgressList, ClientDocuments } from 'screens/admin';
import Translate from 'screens/admin/Translate';
// Others
import { isLoggedIn } from 'utils';


const Routes = () => (
	<Switch>
		<PublicRoute exact path="/login" restricted component={Login} />
		<PrivateRoute exact path="/home" component={Home} />
		<PrivateRoute exact path="/faq" component={Faq} />
		<PrivateRoute exact path="/clients" component={Clients} />
		<PrivateRoute exact path="/clientDocuments/:id" component={ClientDocuments} />
		<PrivateRoute exact path="/work-progress" component={WorkProgressList} />
		<PrivateRoute exact path="/users" component={Users} />
		<PrivateRoute path="/deals/:name/:index?" component={Deal} />
		<PrivateRoute path="/escrituration/:name/:index/:payment_method/:deal_id?" component={Escrituration} />
		<PrivateRoute path="/escriturationDocuments/:name/:index/:deal_id/:type?" component={EscriturationDocuments} />
		<PrivateRoute path="/Documents/:name/:deal_id" component={Documents} />
		<PrivateRoute path="/proccess" component={Proccess} />
		<PrivateRoute path="/Autorization" component={Autorization} />
		<PrivateRoute path="/EscriturationProcess/:deal_id?" component={EscriturationProccess} />
		<PrivateRoute path="/deed" component={Deed} />
		<PrivateRoute path="/delivery" component={Delivery} />
		<PrivateRoute path="/deliverySuccess" component={DeliverySuccess} />
		<PrivateRoute exact path="/credit/:name/:index?" component={Credit} />
		<PrivateRoute exact path="/progress/:name/:department?" component={Progress} />
		<PrivateRoute exact path="/profile" component={Profile} />
		<PrivateRoute exact path="/security" component={Security} />
		<PrivateRoute path="/translate" component={Translate} />
		<Redirect path="/**" to="/login" />
		{/* <Route component={Page404} /> */}
	</Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => {
	return isLoggedIn() ? (
		<Route
			{...rest}
			render={(props) => (
				<DashboardContainer {...props}>
					<Component {...props} />
				</DashboardContainer>
			)}
		/>
	) : (
		<Redirect to="/login" />
	);
};

// Restricted
// 		false meaning public route
//		true meaning restricted route
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				isLoggedIn() && restricted ? (
					<Redirect to="/home" />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default Routes;
