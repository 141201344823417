// Common
import { useEffect, createRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Others
import { Disclosure, Transition } from '@headlessui/react';
import { FiArrowDownRight, FiArrowUpLeft } from 'react-icons/fi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BsPencil, BsCardList, BsPencilSquare, BsTrash, BsArrowsMove } from 'react-icons/bs'
import { apiInstance } from 'services/instance';
import Modal from 'components/shared/Modal';
import '../index.css';
import { swal, swalConfirm, swalLoading, swalClose } from 'utils';
import RichText from 'components/shared/RichText';
import { useTranslation } from 'react-i18next';
import { editFaqAsync } from 'redux/redux';
import ReactGA from "react-ga"
import { useSelector, useDispatch } from 'react-redux';
import { FaQq } from 'react-icons/fa';

ReactGA.initialize("UA-221463012-1")

const Faq = () => {
    const [faqs, setFaqs] = useState([]);
    const [mod, setMod] = useState(null);
    const [update, setUpdate] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const { t } = useTranslation("lang");

    const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm();
    const roles = JSON.parse(sessionStorage.getItem('roles'));
    let submitBtn = createRef();

    const trad = useSelector( (state) => state.langInfo);

    useEffect(() => {
        fetchFaqs();
    }, [trad])

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const onSubmit = (data) => {
        if (update) {
            const aux = faqs.map((faq) => {
                if (faq.id === update) {
                    faq.question = data.question;
                    faq.response = data.response;
                }
                return faq;
            });
            setFaqs(aux);
            updateFaqsOrder(aux);
            setMod(null);
            reset();
            return;
        }
        createFaq(data);
    };

    const createFaq = async (data) => {
        apiInstance.post('/faqs', data)
            .then(({ data: res }) => {
                swal('success', res.message);
                fetchFaqs();
                reset();
            }).catch((error) => {
                console.log(error);
            });
        handleModal();
    };

    const updateFaqsOrder = async (items) => {
        if( localStorage.getItem("lang") === "es" ){

            apiInstance.put('/faqs/1', { items })
                .then(({ data: res }) => {
                    if (update) swal('success', res.message);
                    return;
                }).catch((error) => {
                    console.log(error);
                })
            setShowModal(false);

        }
        else{

            let idioma = JSON.parse(localStorage.getItem("jsonLang"));
            for(let k in items){
                const item = items[k];
                idioma[item.responseKey] = item.response;
                idioma[item.questionKey] = item.question;
            }
            const data ={
                translation: idioma
            }
            console.log(data);
            dispatch(editFaqAsync({data}));
            setShowModal(false);
            return;

        }
    };

    const fetchFaqs = async () => {
        apiInstance.get('/faqs')
            .then(({ data: res }) => {
                if(localStorage.getItem("lang") === "es"){
                    setFaqs(res.items);
                }
                else{
                    let res2 = res.items?.map((faq)=>{
                        if(( trad.data?.hasOwnProperty(faq.response ))){
                            faq.responseKey = faq.response;
                            faq.response = trad.data[faq.response];
                        }
                        if(( trad.data?.hasOwnProperty(faq.question ))){
                            faq.questionKey = faq.question;
                            faq.question = trad.data[faq.question];
                        }
                        return faq;
                    })
                    setFaqs(res2);
                }
            }
        ).catch();
    }

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(faqs);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        items.map((e, index) => e.position = index + 1);
        updateFaqsOrder(items);
        setFaqs(items);
    };

    const handleUpdate = (faq) => {
        setUpdate(faq.id);
        handleModal();
        setValue('question', faq.question);
        setValue('response', faq.response);
    };

    const handleDelete = async ({ id }) => {
        const MESSAGE = '¿Seguro que desea eliminar la pregunta?';

        swalConfirm(MESSAGE, 'Aceptar').then((result) => {
            if (result.isConfirmed) {
                swalLoading('Elminando Faq');
                apiInstance.delete(`/faqs/${id}`)
                    .then(({ data: res }) => {
                        swalClose();
                        fetchFaqs();
                    }).catch(console.log);
            }
        });
    };

    const roleHandler = () => {
        const initRoles = ['root'];
        let flag = false;
        roles.forEach((role) => flag = initRoles?.includes(role) ? true : false);
        if (!flag) { return false; }
        return true;
    };

    return (
        <>
            <div className="bg-white rounded-5xl mx-8 shadow-lg">
                <div className="h-auto p-4 sm:p-10 justify-center align-center">
                    <div className="my-auto">
                        <h4 className="text-3xl font-bold text-center">{t("faqLabel")}</h4>

                        {roleHandler(roles) && <div className="flex justify-between sm:px-20 my-4">
                            <button onClick={handleModal}
                                className="flex gap-2 p-2 rounded-2xl bg-brand-50 hover:bg-brand-100 focus:outline-none transition">
                                <BsPencil className="text-brand" size={30} />
                                <p className="text-brand font-semibold text-lg">{t("createLabel")}</p>
                            </button>
                            <button type="button" onClick={() => setMod(!mod)}
                                className="flex items-center gap-2 btn btn-primary px-3 py-2">
                                <p>{mod ? t("watchLabel") : t("modifyLabel")}</p>
                                <BsCardList />
                            </button>
                        </div>}

                    </div>
                    <div className="w-full px-4 py-4 sm:px-20 sm:py-10 shadow-xl rounded-2xl space-y-2">
                        {!faqs.length && <h1 className="text-3xl sm:text-6xl font-bold text-red-200">{t("noDataAvailableLabel")}</h1>}

                        {mod && <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="faqs">
                                {(provided) => (
                                    <div className="faqs" {...provided.droppableProps} ref={provided.innerRef}>
                                        {faqs.map((e, i) => {
                                            return (
                                                <Draggable key={e.id} draggableId={`${e.id}`} index={i}>
                                                    {(provided) => (

                                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
                                                            className="flex items-center justify-center">
                                                            <div className="px-3 text-2xl text-brand-400">
                                                                <BsArrowsMove />
                                                            </div>

                                                            <div className="mb-2 rounded-xl flex justify-between items-center w-full px-4 p-1 text-sm font-medium text-white bg-brand-300 hover:bg-brand-400">
                                                                <h1 className="text-xl font-bold text-white">{e.question}</h1>
                                                                <div className="flex gap-2">
                                                                    <p onClick={() => handleUpdate(e)} className="text-white text-xl cursor-pointer p-2">
                                                                        <BsPencilSquare />
                                                                    </p>
                                                                    <p onClick={() => handleDelete(e)} className="text-white text-xl cursor-pointer p-2">
                                                                        <BsTrash />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>}

                        {!mod && faqs.map((e, i) =>
                            <Disclosure key={i}>
                                {({ open }) =>
                                    <>
                                        <Disclosure.Button className="rounded-xl flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-white bg-brand-400 hover:bg-brand focus:outline-none focus-visible:ring focus-visible:ring-brand-light focus-visible:ring-opacity-75 transition">
                                            <span className="text-lg text-left">{e.position} - {e.question}</span>
                                            {open ? <FiArrowUpLeft size={25} /> : <FiArrowDownRight size={25} />}
                                        </Disclosure.Button>
                                        <Transition enter="transition-opacity duration-150" enterFrom="opacity-0" enterTo="opacity-100"
                                            leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-600">
                                                <div className="richtext" dangerouslySetInnerHTML={{ __html: e.response }}></div>
                                            </Disclosure.Panel>
                                        </Transition>
                                    </>
                                }
                            </Disclosure>
                        )}
                    </div>
                </div>
            </div>
            <Modal shown={showModal} toggleModal={handleModal} title="Crear Faq" saved={() => submitBtn.click()}>
                <form id="form" onSubmit={handleSubmit(onSubmit)} className="form space-y-6">
                    <div className="group text-left">
                        <label htmlFor="question" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("questionTitleLabel")}</label>
                        <input id="question" placeholder="Something..." {...register("question", { required: true })} className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                        {errors.question && <span className="ml-2 text-brand-400 text-sm">{t("askAQuestionLabel")}</span>}
                    </div>

                    <div className="group text-left">
                        <label className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("answerTitleLabel")}</label>
                        <Controller control={control} name="response" render={({ field: { onChange, onBlur, value } }) => (
                            <RichText onBlur={onBlur} onChange={onChange} value={value} />
                        )} />
                        {errors.response && <span className="ml-2 text-brand-400 text-sm">{t("answerLabel")}</span>}
                    </div>
                    <input type="submit" ref={input => submitBtn = input} hidden />
                </form>
            </Modal>
        </>
    );
};

export default Faq;