import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../src/languages/en.json";
import es from "../src/languages/es.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: "es",
    react:{wait: true},
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    resources: {
      en: {
        lang: en
      },
      es: {
        lang: es
      }
    }
  });

  export default i18n; 