import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ResponsiveCarousel = ({ images, id, development, className }) => {

    const imagesHandler = images.map((e) => (`https://api-portalclientes.idex.cc/storage/work-progress/${id}-${development}/${e.image}`));

    return (
        <div className={className}>
            <Carousel showThumbs={false} infiniteLoop={images.length} swipeable={true} autoPlay={true} showArrows={true}>
                {imagesHandler.map((e, i) =>
                    <div key={i}>
                        <img src={e} className="flex object-contain" alt={e} />
                    </div>)}
            </Carousel>
        </div>
    );
}

export default ResponsiveCarousel;