// Common
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// Components
import Loading from '../components/shared/Loading';
// Others
import { apiInstance } from '../services/instance';
import { FaShieldAlt } from 'react-icons/fa';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { swal } from '../utils';

import ReactGA from "react-ga"
import { useTranslation } from 'react-i18next';

ReactGA.initialize("UA-221463012-1")

const Security = () => {
    const { t } = useTranslation("lang");
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [userInfo, setUserInfo] = useState(JSON.parse(sessionStorage.getItem('info')));
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(false);

    useEffect(() => {
        setUserInfo(JSON.parse(sessionStorage.getItem('info')));
    }, []);

    const updatePassword = async (form) => {
        await apiInstance.put(`/passwords/${userInfo.id}`, form)
            .then(async (res) => {
                swal('success', 'Su contraseña ha sido actualizada');
                history.push('/home');
            }).catch((error) => {
                swal('error', error.response.data.message);
            });
        setLoading(false);
    };

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const onSubmit = (data) => {
        setLoading(true);
        if (data.newPassword === data.newPasswordConfirm) {
            delete data.newPasswordConfirm;
            updatePassword(data);
            return;
        }
        swal('error', 'Su nueva contraseña no coincide con su confirmacion');
        setLoading(false);
    };

    return (
        <>
            <div className="bg-white rounded-5xl mx-8 shadow-lg p-10 text-center">
                <div className="picture">
                    <h1 className="text-2xl font-bold">{t("passwordSecurityLabel")}</h1>
                    <div className="items-center justify-center flex rounded-full h-36 w-36 sm:h-40 sm:w-40 mx-auto my-6 border-2 border-brand-light">
                        <div className="w-4/5 flex justify-center">
                            <FaShieldAlt size={80} />
                        </div>
                    </div>

                    <form id="form" onSubmit={handleSubmit(onSubmit)} className="form sm:px-10 grid lg:grid-cols-2 gap-6 my-10">
                        <div className="group text-left col-span-2">
                            <label htmlFor="password" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("currentPasswordSecurityLabel")}</label>
                            <div className="relative">
                                <input id="password" {...register("password", { required: true })} type={type ? "text" : "password"} className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                                <button onClick={() => setType(!type)} type="button" className="p-2 absolute right-2 top-2 focus:outline-none">
                                    {type ? <BsEyeSlash size={22} /> : <BsEye size={22} />}
                                </button>
                            </div>
                            {errors.password && <span className="ml-2 text-brand-400 text-sm">{t("typeCurrentPasswordSecurityLabel")}</span>}
                        </div>
                        <div className="group text-left">
                            <label htmlFor="newPassword" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("newPasswordSecurityLabel")}</label>
                            <input id="newPassword" {...register("newPassword", { required: true })} type="text" className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                            {errors.newPassword && <span className="ml-2 text-brand-400 text-sm">{t("typeNewPasswordSecurityLabel")}</span>}
                        </div>
                        <div className="group text-left">
                            <label htmlFor="newPasswordConfirm" className="block text-xl font-bold ml-2 mb-2 text-gray-700">{t("confirmNewPassSecurityLabel")}</label>
                            <input id="newPasswordConfirm" {...register("newPasswordConfirm", { required: true })} type="text" className="text-lg px-3 input input-primary h-14 rounded-2xl" />
                            {errors.newPasswordConfirm && <span className="ml-2 text-brand-400 text-sm">{t("typeNewPasswordSecurityLabel")}</span>}
                        </div>
                    </form>
                </div>
                <button disabled={loading} form="form" type="submit" className="mx-auto px-14 py-3 btn btn-primary disabled:opacity-50">
                    {loading && <Loading />}
                    {!loading && <p className="w-full font-medium text-center">{t("saveSecurityLabel")}</p>}
                </button>
            </div>
        </>
    );
};

export default Security;