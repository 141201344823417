// Common
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// Images
import Logo from 'assets/images/logos/Logo-idex-1.png';
import Alaya from 'assets/images/Alaya.png'
// Others
import Loading from 'components/shared/Loading';
import { apiInstance } from 'services/instance';
import { login, swal } from 'utils';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
//languages
import { useTranslation } from 'react-i18next';

import ReactGA from "react-ga"

ReactGA.initialize("UA-221463012-1");

const Login = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [forgot, setForgot] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const history = useHistory();

    const { t } = useTranslation("lang");

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    useEffect(() => {
        document.title = 'IDEX - Es momento de vivir mejor';
        return () => {
        }
    }, [])

    const onSubmit = (data) => {
        setLoading(true);
        forgot ? recoverPassword(data) : getCredentials(data);
    };

    const getCredentials = async (form) => {
        await apiInstance.post('/login', form)
            .then(({ data: res }) => {
                setLoading(false);
                login(res.token, JSON.stringify(res.items), JSON.stringify(res.access.Roles));
                if (res.access.Roles.find(r => (r === 'root' || r === 'admin'))) {
                    history.push('/users');
                } else {
                    history.push('/home');
                }
            }).catch(({ response: error }) => {
                swal('error', error?.data?.message);
                setLoading(false);
            });
    };

    const recoverPassword = async (form) => {
        await apiInstance.post('/password/reset', form)
            .then(({ data: res }) => {
                swal('success', res.message);
                reset();
            }).catch(({ response: error }) => {
                swal('error', error.data.message);
            });
        setLoading(false);
    };

    const toggleForgot = () => {
        setForgot(!forgot)
        reset();
    };

    return (
        <div className="grid md:grid-cols-12 h-screen">
            <div className="px-10 py-16 md:col-span-5 bg-white">
                {/* Left Section */}
                <div className="my-auto md:mx-auto lg:w-10/12">
                    <img src={Logo} className="w-60 md:w-64" alt="IDEX Logo" />
                    <h1 className="my-12 text-4xl font-semibold">{t("loginTitleLabel")}</h1>
                    {/* Form Section*/}
                    <form id="form" onSubmit={handleSubmit(onSubmit)}>
                        {/* Login */}
                        {!forgot && <div className="space-y-6 mb-6">
                            <div>
                                <label htmlFor="email" className="block text-xl font-medium ml-2 mb-2">{t("emailLabel")}</label>
                                <input id="email" {...register("email", { required: true })} type="email" className="input input-primary px-4 py-2" />
                                {errors.email && <span className="ml-2 text-brand-400 text-sm">{t("tryAnotherPassLabel")}</span>}
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-xl font-medium ml-2 mb-2">{t("passwordLabel")}</label>
                                <div className="relative">
                                    <input id="password" {...register("password", { required: true })} type={visible ? "text" : "password"} className="input input-primary px-4 py-2" />
                                    <button onClick={() => setVisible(!visible)} type="button" className="p-2 absolute right-2 focus:outline-none">
                                        {visible ? <BsEyeSlash size={24} /> : <BsEye size={24} />}
                                    </button>
                                </div>
                                {errors.password && <span className="ml-2 text-brand-400 text-sm">{t("tryAnotherEmailLabel")}</span>}
                            </div>
                        </div>}
                        {/* END: Login */}

                        {/* Recover */}
                        {forgot && <div>
                            <div className="mb-32">
                                <label htmlFor="email" className="block text-xl font-medium ml-2 mb-2">{t("emailLabel")}</label>
                                <input id="email" {...register("email", { required: true })} type="email" className="input input-primary px-4 py-2" />
                                {errors.email && <span className="ml-2 text-brand-400 text-sm">{t("enterAnEmail")}</span>}
                            </div>
                        </div>}
                        {/* END: Recover */}
                    </form>
                    {/* END: Form Section */}
                    <div className="space-y-6">
                        <div className="block text-right">
                            <p className="font-medium">
                                {forgot ? t("doYouHaveAnAccountLabel") : t("forgotYourPasswordLabel")}
                                <button onClick={() => toggleForgot()} className="text-brand font-semibold ml-2 focus:outline-none">
                                    {forgot ? t("loginButton") : t("recoverLabel")}
                                </button>
                            </p>
                        </div>

                        <div>
                            <button disabled={loading} form="form" type="submit" className="px-5 py-3 w-full btn btn-primary disabled:opacity-50">
                                {loading && <Loading />}
                                {!loading && <p>{forgot ? t("askForAnAccountLabel") : t("loginButton")}</p>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* END: Left Section */}

            {/* Right Section */}
            <div className="hidden bg-gray-100 md:flex md:col-span-7">
                <img src={Alaya} className="object-cover" alt="Illustration" />
            </div>
            {/* <LangSelector/> */}
            {/* END: Right Section */}
        </div>
    );
};

export default Login;