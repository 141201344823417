// Common
import React, { useState } from "react";
import ReactGA from "react-ga";
import Logo from "assets/images/Proccess.png";
import Logo1 from "assets/images/proccess2.png";
import Logo2 from "assets/images/proccess3.png";
import { useTranslation } from "react-i18next";

ReactGA.initialize("UA-221463012-1");

const Proccess = () => {
  const [index, setIndex] = useState(1);
  const { t } = useTranslation("lang");
  return (
    <>
      <div className="w-full card shadow-xl  rounded-3xl p-1 sm:p-8  space-x-1/2 bg-white ">
        {index === 1 ? (
          <>
            <img src={Logo} alt="1" />
            <h4 className="text-2xl font-regular text-center my-2 mx-10 md:text-3xl md:mx-40">
              {t("notificationInProgressLabel")}
            </h4>
          </>
        ) : index === 2 ? (
          <>
            <img src={Logo1} alt="1" />
            <h4 className="text-2xl font-regular text-center my-2 mx-10 md:text-3xl md:mx-40">
              {t("notificationWeNeedYourHelpLabel")}
            </h4>
          </>
        ) : (
          <>
            <img src={Logo2} alt="1" />
            <h4 className="text-2xl font-regular text-center my-2 mx-10 md:text-3xl md:mx-40">
              {t("notificationWeNeedDocsLabel")}
            </h4>
          </>
        )}
        <div
          className="w-1/2 flex justify-between items-center px-10 py-20 mx-auto"
        >
          <button
            onClick={() => setIndex(1)}
            style={{
              backgroundColor: index === 1 ? "#EC3422" : "#ECEEF1",
              width: "70px",
              height: "15px",
              borderRadius: "10px",
            }}
          />
          <button
            onClick={() => setIndex(2)}
            style={{
              backgroundColor: index === 2 ? "#EC3422" : "#ECEEF1",
              width: "70px",
              height: "15px",
              borderRadius: "10px",
            }}
          />
          <button
            onClick={() => setIndex(3)}
            style={{
              backgroundColor: index === 3 ? "#EC3422" : "#ECEEF1",
              width: "70px",
              height: "15px",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Proccess;
