import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
// Others
import { toCurrency } from 'utils';
import { useTranslation } from 'react-i18next';

const Card = ({ info, img }) => {
    const [creditInfo, setCreditInfo] = useState([]);
    const { card_info: cardInfo } = info;
    const { t } = useTranslation("lang");

    useEffect(() => {
        infoHandler(cardInfo);
    }, [cardInfo]);
    
    const infoHandler = (cardInfo) => {
        let [paid, paidAmount, charged, chargedAmount] = [0, 0, 0, 0];
        const total = toCurrency(parseFloat(cardInfo.deposit));
        
        paid = cardInfo.percentage_paid.toFixed(2);
        charged = (100 - paid).toFixed(2);
        
        paidAmount = toCurrency(cardInfo.total_customer_paid_month);
        chargedAmount = toCurrency(cardInfo.deposit - cardInfo.total_customer_paid_month); 

        setCreditInfo([total, paid, paidAmount, charged, chargedAmount]);
    };

    return (
        <div className="h-1/5 p-6 sm:p-10 bg-white mx-8 rounded-4xl sm:rounded-5xl grid sm:grid-cols-2 shadow-lg items-center">
            <div className="info space-y-6">
                <h4 className="text-2xl font-bold">{cardInfo.department_development} ({cardInfo.department}-{cardInfo.prototype})</h4>
                <div className="details text-lg space-y-2">
                    <p className="font-medium text-gray-500">{t("totalDownPaymentLabel")}:
                        <span className="text-gray-800"> {creditInfo[0]}</span>
                    </p>
                    <div className="cols grid grid-cols-2 space-y-4 font-medium text-gray-500">
                        <p className="mt-4">{t("paidDashboardLabel")}: <span className="text-gray-800">{creditInfo[1]}%</span></p>
                        <p className="">{t("paidDashboardLabel")}: <span className="text-gray-800">{creditInfo[2]}</span></p>
                        <p className="">{t("debtDashboardLabel")}: <span className="text-gray-800">{creditInfo[3]}%</span></p>
                        <p className="">{t("debtDashboardLabel")}: <span className="text-brand-light">{creditInfo[4]}</span></p>
                    </div>
                </div>
                <div className="">
                    <Link to={`/deals/${cardInfo.department_development.toLowerCase()}/${info.index}`}>
                        <button className="w-full sm:w-1/2 px-6 py-3 sm:mx-4 bg-brand rounded-full text-white focus:outline-none focus:ring focus:ring-offset-2 hover:bg-brand-light focus:ring-brand focus:ring-opacity-50 active:bg-brand-dark">
                            <div className="flex space-x-2 items-center justify-center">
                                <p className="text-lg">{t("detailsLabel")}</p><FaArrowRight size={30} />
                            </div>
                        </button>
                    </Link>
                </div>
            </div>

            <div className="items-center justify-center hidden sm:flex rounded-full h-60 w-60 mx-auto bg-gray-100">
                <div className="w-4/5">
                    <img src={img} className="w-full" alt="Logo" />
                </div>
            </div>
        </div>
    );
};

export default Card;
