// Common
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import Logo from "assets/images/escriturationProcess.png";
import GoogleDocsViewer from 'react-google-docs-viewer'
import { getDeedAsync } from "redux/redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiInstance } from "services/instance";
import { useTranslation } from "react-i18next";



ReactGA.initialize("UA-221463012-1");

const EscriturationProccess = () => {
  const { deal_id } = useParams();

  const {urlDeed} = useSelector((state)=>state)
  const dispatch = useDispatch();

  const { t } = useTranslation("");
  
  useEffect(()=>{
    if(deal_id){
      dispatch(getDeedAsync({deal_id}))
    }
  }, [deal_id])
  const [index, setIndex] = useState(1);

  return (
    <>
      <div className="w-full card shadow-xl  rounded-3xl p-1 sm:p-8  space-x-1/2 bg-white ">
        {index === 1 ? (
          <>
            <img src={Logo} alt="1" />
            <h4 className="text-2xl font-regular text-center my-2 mx-10 md:text-3xl md:mx-40">
            {t("notificationInProgressLabel")}
            </h4>
          </>
        ) :  (
        <>
          <GoogleDocsViewer
            width="100%"
            height="780px"
            fileUrl={`https://api-portalclientes.idex.cc/storage/${urlDeed?.items?.[0]?.path}`}
          />
        </>
        )}
        <div
          className="w-1/3 flex justify-between items-center px-10 py-20 mx-auto"
        >
          <button
            onClick={() => setIndex(1)}
            style={{
              backgroundColor: index === 1 ? "#EC3422" : "#ECEEF1",
              width: "70px",
              height: "15px",
              borderRadius: "10px",
            }}
          />
          <button
            onClick={() => setIndex(2)}
            style={{
              backgroundColor: index === 2 ? "#EC3422" : "#ECEEF1",
              width: "70px",
              height: "15px",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EscriturationProccess;
