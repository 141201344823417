// Common
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import Dropdown from '../components/shared/Dropdown';
import { Disclosure } from '@headlessui/react';
// Icons
import { FaBell, FaChevronLeft } from 'react-icons/fa';
import { CgMenuLeft } from 'react-icons/cg';
import { apiInstance } from 'services/instance';
import { toCurrency } from 'utils';
import {setEcho} from 'config/echo'
import {toast} from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LangSelector from './shared/LangSelector';

const Navbar = (props) => {
    const [notifications, setNofications] = useState([]);
    const [areShown, setAreShown] = useState(false);
    const history = useHistory();
    const [newNotification, setnewNotification] = useState({});
    const { t } = useTranslation("lang");

    useEffect(()=>{

        if(Object.keys(newNotification).length){
            const news = JSON.parse(JSON.stringify(notifications));
            console.log(news)
            news.unshift(newNotification);
            setNofications(news)
            toast.success(newNotification.message);
        }
    },[newNotification])



    const insertNotification = (notification)=>{
        let body = JSON.parse(notification.body)
        setnewNotification(body)
    }
    useEffect(() => {
        fetchNotifications();
    
        const loadEcho = async ()=>{
            window.Echo =  await setEcho();
            let user = await sessionStorage.getItem('info');
            user = JSON.parse(user).id

            window.Echo.private(`App.Models.User.${user}`).notification(insertNotification)

        }

        loadEcho();

    }, [])



    const fetchNotifications = async () => {
        await apiInstance.get('/notifications')
            .then(({ data: { items } }) => {
                setNofications(items);
                const aux = 'IDEX - Es momento de vivir mejor';
                document.title = items.length ? `IDEX (${items.length})` : aux;
                console.log(items);
            }).catch(console.log)
    };

    const cleanNotif = async ({ id }) => {
        await apiInstance.delete(`/notifications/${id}`)
            .then(fetchNotifications()).catch(console.log);
    };

    return (
        <nav className="flex py-5 bg-white w-full items-center justify-between px-8 md:sticky top-0 shadow-md z-10">
            <div className="left flex flex-row items-center justify-center space-x-2 sm:space-x-10">
                <button className="font-bold p-1 text-gray-800 border-2 border-gray-800 rounded-xl focus:outline-none active:bg-gray-800" onClick={() => history.goBack()}>
                    <FaChevronLeft className="active:text-white" size={25} />
                </button>
                <button className="uppercase font-bold text-gray-800 md:hidden "
                    type="button" onClick={() => props.toggleSidebar()}>
                    <CgMenuLeft size={26} />
                </button>
            </div>
            <div className="right flex flex-row items-center justify-center space-x-2 sm:space-x-10">
            <LangSelector/>
                <div className="relative">
                    <button onClick={() => setAreShown(!areShown)} disabled={!notifications.length}
                        className="relative focus:outline-none disabled:opacity-60">
                        <FaBell size={25} />
                        {(notifications.length !== 0) && <div className="w-5 h-5 bg-brand rounded-full absolute -top-1 -right-2 flex justify-center items-center">
                            <p className="text-xs font-bold text-white">{notifications.length}</p>
                            </div>}
                    </button>

                    {areShown && <div className="absolute top-10 sm:top-4 -right-36 sm:right-4 w-72">
                        <div className="w-full max-w-md p-2 mx-auto bg-white rounded-2xl space-y-2 h-52 overflow-y-auto">
                            {notifications.map((e, i) => {
                                return (
                                    <Disclosure as="div" key={i}>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-brand-900 bg-brand-100 rounded-lg hover:bg-brand-200 focus:outline-none focus-visible:ring focus-visible:ring-brand-500 focus-visible:ring-opacity-75">
                                                    <span>{e.type === 'payment' ? t("paymentNotificationLabel") :  e.type==='other'? t("documentLabel"): t("buildingProgressNavbarLabel")}</span>
                                                    <p className={`${open ? ' transform rotate-180' : ''} w-5 h-5 text-brand-500`}>V</p>
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                    <p>{e.message}</p>
                                                    <p>{e.development_information}</p>
                                                    {e.status!== undefined && (
                                                        <div className="flex justify-between">
                                                        <p>{e.status === 'pending' ? t("pendingLabel") : t("lateLabel")}</p>
                                                        <p>{toCurrency(parseFloat(e.amount))}</p>
                                                    </div>
                                                    )}
                                                    <button onClick={() => cleanNotif(e)}
                                                    className="w-full bg-brand-200 rounded-md p-1 hover:bg-brand-300 mt-1">
                                                        <p className="text-white">{t("cleanLabel")}</p>
                                                    </button>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                );
                            })}
                        </div>
                    </div>}
                </div>

                <div className="border-2 border-brand-light rounded-md hover:bg-brand-50 z-20">
                    <Dropdown />
                </div>
            </div>
        </nav>
    );
}

export default Navbar;