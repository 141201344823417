// Common
import { useEffect, useState } from 'react';
// Components
import Card from 'components/shared/Card';
import Loading from 'components/shared/Loading';
// Others
import { ImSad } from 'react-icons/im';
import { useCreditInfo } from 'hooks/useCreditInfo';
import { getLogo, swal } from 'utils';
import { apiInstance } from 'services/instance';

import ReactGA from "react-ga"
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';


ReactGA.initialize("UA-221463012-1")

const Home = () => {
    const [deals, setDeals] = useState([]);
    const session = JSON.parse(sessionStorage.getItem('info'));
    const { t } = useTranslation("lang");
    const { items, areLoading } = useCreditInfo();


    useEffect(() => {
        setDeals(Array.from(items));
        fetchNotifications();
        return (() => {
            setDeals([]);
        });
    }, [items]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const fetchNotifications = async () => {
        const notified = sessionStorage.getItem('notified');

        await apiInstance.get('/notifications')
            .then(({ data: response }) => {
                if (response.items.length && !notified) {

                    const payments = response.items.filter(({ type }) => type === 'payment').length;
                    const progresses = response.items.filter(({ type }) => type !== 'payment').length;

                    const message = `${t("haveTextMsg")} ${payments && !progresses ? `${payments} ${t("notificationsTextMsg")}` :
                        progresses && !payments ? `${progresses} ${t("buildingAdvancementTextMsg")}` :
                            `${t("haveTextMsg")} ${payments} ${t("notificationsTextMsgAnd")} ${progresses} ${t("buildingAdvancementsTextMsg")}`}`

                    swal('Hey!', message)
                }
            }).catch(console.log)
        if (!notified) {
            sessionStorage.setItem('notified', true);
        }

    };

    const userDeals = deals.map((item, index) => {
        const { department_development: dev } = item.card_info;
        return <Card key={index} img={getLogo(dev)} info={{ index, ...item }} />
    });

    const noDeals = () => {
        return (
            <>
                <h1 className="text-4xl sm:text-6xl text-brand-300 font-bold">{t("noInfoMessageLabel")}</h1>
                <ImSad size="120" className="text-brand-300" />
            </>
        );
    };

    return (
        <>
            <div className="headline ml-10 py-4">
                <p className="text-3xl font-bold text-gray-800">{t("welcomeMessageLabel")} {session.name}</p>
            </div>

            {areLoading ?
                <div className="flex justify-center items-center h-96">
                    <Loading color="#F4857B" type="Oval" width={150} height={150} />
                </div>
                :
                <div className="space-y-10 pb-20">
                    {deals.length ? userDeals : noDeals()}
                </div>
            }
        </>
    );
};

export default Home;