// Common
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Components
import Loading from 'components/shared/Loading';
// Others
import { apiInstance } from 'services/instance';
import { useCreditInfo } from 'hooks/useCreditInfo';
import { classNames, toCurrency, getLogo, futurePayment, getAccount } from 'utils';
import { FaDownload, FaCheckCircle } from 'react-icons/fa';

import ReactGA from "react-ga"
import { useTranslation } from 'react-i18next';

ReactGA.initialize("UA-221463012-1")

// const MercadoPago = window.MercadoPago;

const Credit = () => {
    const { name, index } = useParams();
    const { t } = useTranslation("lang");
    const { items: { credit: details, card_info: cardInfo }, areLoading } = useCreditInfo(name, index);


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    // const getPreference = async (payment) => {
    //     let date = new Date(payment.proximo_pago);
    //     date = date.toLocaleDateString('es-MX', { month: 'long' });

    //     const form = {
    //         payments: [
    //             {
    //                 neodata_customer_id: payment.id_cliente_neodata,
    //                 customer: payment.cliente,
    //                 department: payment.vivienda,
    //                 payment_month: date,
    //                 real_state_development: payment.desarrollo,
    //                 price: parseFloat(payment.monto_pago)
    //             }
    //         ]
    //     };

    //     await apiInstance.post('/mercadopago-preference', form)
    //         .then((res) => {
    //             pay(res.data.items);
    //         }).catch(console.log);
    // };

    // const pay = (preference) => {
    //     if (MercadoPago) {
    //         const mp = new MercadoPago('APP_USR-fc00c433-f4fe-4a73-a30d-7cf5170bb3bb', { locale: 'es-MX' });
    //         // Inicializa el checkout
    //         mp.checkout({
    //             preference: {
    //                 id: preference
    //             },
    //             autoOpen: true,
    //         });
    //     }
    // };

    const handleDownload = async () => {
        await apiInstance.get(`/download-file/${cardInfo.department}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `tabla-amortizacion.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(console.log);
    };

    return (
        <>
            {areLoading && <div className="flex justify-center items-center h-96">
                <Loading color="#F4857B" type="Oval" width={150} height={150} />
            </div>}
            {!areLoading && <div className="h-auto bg-white rounded-5xl sm:mx-8 shadow-lg sm:p-10">
                <section className="flex flex-col sm:flex-row justify-around items-center p-4 pb-0 sm:p-6">
                    <div>
                        <div className="hidden sm:flex items-center justify-center rounded-full h-24 w-24 sm:h-44 sm:w-44 bg-gray-200">
                            <div className="w-4/5">
                                <img src={cardInfo && getLogo(cardInfo.department_development)} className="w-full" alt="" />
                            </div>
                        </div>
                        <p className="text-center font-bold mt-2 text-xl text-gray-600">{cardInfo?.department}</p>
                    </div>
                    <div>
                        <h4 className="text-2xl sm:text-3xl font-bold text-center mb-2">{t("amortizationScheduleLabel")}</h4>
                        <div className="border border-brand rounded-xl p-2 my-2">
                            <p className="text-center">{t("depositAccountLabel")}: {getAccount(cardInfo.department_development)[0]} </p>
                            <p className="text-center">{t("bankLabel")}: {getAccount(cardInfo.department_development)[1]} </p>
                            <p className="text-center">{t("interBankCodeLabel")}: {getAccount(cardInfo.department_development)[2]} </p>
                            <p className="text-center">{t("ownerLabel")}: {getAccount(cardInfo.department_development)[3]} </p>
                            <p className="text-center">{cardInfo.department.split('-')[1]}{cardInfo.department.split('-')[3]} {details[0]?.cliente ?? ''}</p>
                        </div>
                    </div>
                    <button onClick={() => handleDownload()}
                        className="w-auto p-1 sm:p-2 bg-brand rounded-xl text-white focus:outline-none focus:ring focus:ring-offset-2 hover:bg-brand-light focus:ring-brand focus:ring-opacity-50 active:bg-brand-dark">
                        <div className="flex items-center justify-center px-2">
                            <p className="uppercase font-medium mx-2">{t("downloadLabel")}</p>
                            <FaDownload size={20} />
                        </div>
                    </button>
                </section>

                <section className="data justify-center align-center w-full card shadow-xl rounded-5xl py-2 sm:py-8 overflow-x-auto">
                    <table className="table-auto w-full divide-y">
                        <thead className="text-center">
                            <tr className="table-row">
                                <th className="p-2 sm:py-4">{t("paymentDateLabel")}</th>
                                <th className="p-2 sm:py-4">{t("amountLabel")}</th>
                                <th className="p-2 sm:py-4">{t("outstandingBalanceLabel")}</th>
                                <th className="p-2 sm:py-4">{t("statusLabel")}</th>
                                {/* <th className="p-2 sm:py-4">Pagar</th> */}
                            </tr>
                        </thead>
                        <tbody className="text-center divide-y text-gray-700 font-medium">
                            {details?.map((e, i) => {
                                const { status, available } = futurePayment(e.proximo_pago, parseFloat(e.saldo_pendiente));
                                // if (e.concepto.split('-')[0].toLowerCase() === 'enganche')
                                return (
                                    <tr key={i} className={classNames(!available ? 'bg-white opacity-60' : 'bg-brand-50', 'table-row')}>
                                        <td className="">{e.proximo_pago}</td>
                                        <td className="">{toCurrency(parseFloat(e.monto_pago))}</td>
                                        <td className="">{toCurrency(parseFloat(e.saldo_pendiente))}</td>
                                        <td className="p-2 flex justify-center items-center">
                                            <div className={classNames((status === 'atrasado')||(status === 'late') ? 'bg-brand-400' : (status === 'pendiente')||(status === 'pending') ? 'bg-yellow-500' : 'bg-green-400', 'py-1 px-2 rounded-lg text-white text-sm')}>
                                                <p className="hidden sm:block capitalize font-medium">{status}</p>
                                                <FaCheckCircle size={20} className="sm:hidden" />
                                            </div>
                                        </td>
                                        {/* <td className="">
                                                <button onClick={() => getPreference(e)} disabled={!available}
                                                    className="disabled:opacity-50 w-auto p-1 bg-brand rounded-full text-white focus:outline-none focus:ring focus:ring-offset-2 hover:bg-brand-light focus:ring-brand focus:ring-opacity-50 active:bg-brand-dark">
                                                    <div className="flex items-center justify-center px-2">
                                                        <p className="uppercase font-medium mx-2">$</p>
                                                    </div>
                                                </button>
                                            </td> */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </section>
            </div>}
        </>
    );
};

export default Credit;