// Common
import React from "react";
import DeedImage from "assets/images/delivery.png";
import { useTranslation } from "react-i18next";

const Delivery = () => {
  const { t } = useTranslation("lang");

  return (
    <>
      <div className="w-full card shadow-xl  rounded-3xl p-1 sm:p-8  space-x-1/2 bg-white ">

          <>
            <img src={DeedImage} alt="1" />
            <h4 className="text-xl font-regular text-center my-2 mx-10 md:text-3xlmx-40">
              {t("newApartmentLabel")}
            </h4>
            <h4 className="text-xl font-regular text-center my-10 mx-10 md:text-3xlmx-40">
              {t("weConnectWithYouLabel")}
            </h4>
          </>  
      </div>
    </>
  );
};

export default Delivery;
